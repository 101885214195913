// eslint-disable-next-line import/no-cycle
import { parseMusicXml, setRepeaters } from 'site/components/videoPlayer';
import _ from 'lodash';
import axios from 'axios';
import { Cookie } from './cookie';
import { getSongsData } from '../actions/songs';

export class Api {

  constructor() {
    this.user = null;
    this.dispatch = null;
    this.apiUrl = window.__ENVIRONMENT__.API_URL;
  }

  initialize = (dispatch) => {
    this.dispatch = dispatch;
    const user = Cookie.get('_loginData');

    if (user) {
      const parsedUser = JSON.parse(user);
      this.user = parsedUser;
      this.dispatch({ type: 'SET_USER', user: parsedUser });
    }

    return true;
  };

  axiosFetch = async (
    path,
    {
      authorize = false, method = 'POST', headers = {}, body, ...extra
    },
  ) => {
    if (body && !(body instanceof FormData)) {
      body = JSON.stringify(body);
    }

    const options = _.pickBy({
      url: `${this.apiUrl}${path}`,
      method,
      data: body,
      headers: _.pickBy({
        'Authorization': authorize
          ? `Bearer ${this.user.accessToken}`
          : undefined,
        'Content-Type': body ? 'application/json' : undefined,
        ...headers,
      }),
      ...extra,
    });

    let data;
    let status;
    try {
      ({ data, status } = await axios(options));
    } catch (error) {
      return {
        status: error.response.status || 401,
        data: error.response.data.message,
      };
    }

    return { data, status };
  };

  getSongs = async (onlyFeatured) => {
    const loginData = Cookie.get('_loginData');
    const showDraft = false;

    const { status, data } = await this.axiosFetch(`/song?${Math.random().toString()}`, {
      method: 'GET',
      authorize: !!loginData,
      params: { onlyFeatured, showDraft },

    });

    return { status, data, loginData };
  }

  getFeaturedSongs = async () => {
    const { status, data } = await this.getSongs(true);
    if (status === 200) {
      return data;
    }
  }

  getAllSongs = async () => {
    const { status, data, loginData } = await this.getSongs(false);
    let favSongsData;
    let favSongsStatus;

    if (loginData) {
      const favSongsResponse = await this.axiosFetch('/admin/favorite', {
        method: 'GET',
        authorize: true,
      });
      favSongsData = favSongsResponse.data;
      favSongsStatus = favSongsResponse.status;
    }

    if (status === 401) {
      return;
    }

    let songs = data.map((song) => ({
      ...song,
      tracks: undefined,
      duration: song.tracks.reduce(
        (a, b) => a + (b.audioFile.duration || 0),
        0,
      ),
    }));

    if (status === 200 && favSongsStatus === 200) {
      const favSongsIDs = favSongsData.map((song) => song.songID);

      const favSongs = songs.map((song) => ({
        ...song,
        isFavorite: favSongsIDs.includes(song.songID), // check if any of the songs are favorite for user
      }));
      songs = _.orderBy(songs, ['isFavorite'], ['desc']);

      return favSongs;
    }
    return songs;
  };

  logout = () => {
    this.dispatch({ type: 'SET_USER', user: null });
    this.user = null;
    Cookie.remove('_loginData');
    this.dispatch(getSongsData());
    return true;
  };

  signup = async (signupData) => {
    const { status, data } = (await this.axiosFetch('/auth/create', { body: signupData })) || {};

    if (status === 201) {
      return this.login(signupData);
    }

    throw new Error(`Error: ${data}`);
  };

  login = async (loginData) => {
    const {
      status, data: {
        email, accessToken, roleType,
      } = {}, data,
    } = (await this.axiosFetch('/auth/login', { body: loginData })) || {};

    if (status === 200) {
      this.user = { email, accessToken, isAdmin: roleType === 'SuperAdmin' };
      Cookie.set('_loginData', JSON.stringify(this.user));

      this.dispatch(getSongsData());
      this.dispatch({ type: 'SET_USER', user: this.user });
      return this.user;
    }

    throw new Error(`Error: ${data}`);
  };

  requestPasswordReset = async (email) => {
    const { status } = await this.axiosFetch(`/admin/forgot/${email}`, { body: email });

    if (status === 200) {
      return true;
    }

    if (status === 404) {
      throw new Error('The provided email was not found');
    }

    throw new Error('Oops, something went wrong');
  }

  setPassword = async (newPasswordData) => {
    const { token } = newPasswordData;
    const newPassword = { email: newPasswordData.email, newPassword: newPasswordData.confirmPassword };
    const { status } = await this.axiosFetch(`/admin/reset/${token}`, { body: newPassword });

    if (status === 200) {
      return true;
    }

    throw new Error('Oops, something went wrong');
  }

  addSongToFavorite = async (songID, method) => {
    try {
      const { status, data } = await this.axiosFetch('/admin/favorite', {
        params: { songID },
        method,
        authorize: true,
      });

      return { status, data };
    } catch (error) {
      throw new Error('Error adding song to favorite');
    }
  };

  getSong = async (songId, songName) => {
    const loginData = Cookie.get('_loginData');
    const url = !songName ? `/song/${songId}` : `/song/${songId}/${songName}`;
    const { status, data } = await this.axiosFetch(url, { method: 'GET', authorize: !!loginData });

    if (status === 200) {
      if (data) {
        let xmlUrl = data.tracks[0]?.chartFile?.url;
        const bpm = data?.tracks[0]?.bpm !== 0 ? data?.tracks[0]?.bpm : 120;
        const slowTrackBpm = data.tracks.length > 1 ? data?.tracks[1]?.bpm : bpm * 0.5;

        const parsedXML = await parseMusicXml(xmlUrl, bpm);
        const repeaters = parsedXML !== undefined ? setRepeaters(parsedXML, bpm) : null;

        if (data.tracks.length > 1) xmlUrl = data.tracks[1]?.chartFile?.url;
        const parsedXMLHalfSpeed = await parseMusicXml(xmlUrl, slowTrackBpm);
        const repeatersHalfSpeed = parsedXML !== undefined ? setRepeaters(parsedXMLHalfSpeed, slowTrackBpm) : null;

        const tracksPromises = data.tracks.map(async (track, idx) => (
          {
            ...track,
            audioFile: { ...track.audioFile, name: track.audioFile.url.substring(86) },
            chartFile: {
              ...track.chartFile,
              name: track.chartFile.url.substring(86),
              chartNotes: idx === 0 ? parsedXML : parsedXMLHalfSpeed,
              repeaters: idx === 0 ? repeaters : repeatersHalfSpeed,
            },
            backingAudioFile: track.backingAudioFile ? { ...track.backingAudioFile, name: track.backingAudioFile.url.substring(86) } : {},
            videoFile: track.videoFile ? { ...track.videoFile, name: track.videoFile.url.substring(86) } : {},
          }));

        const mappedTracks = await Promise.all(tracksPromises);

        return ({
          ...data,
          tracks: mappedTracks,
        });
      }
    }

    return status;
  };

  getKeys = async () => {
    const { status, data } = await this.axiosFetch('/keys', { authorize: false, method: 'GET' });

    if (status === 200) {
      return data;
    }

    throw new Error('Error when downloading keys');
  }
}
