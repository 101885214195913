import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import React from 'react';
import { Header } from 'semantic-ui-react';

export const DisplayMessage = ({
  header, content, helmet, className = '',
}) => (
  <div className={`display-message flex items-center flex-column w-100 pa3 min-vh-100 ${className}`}>
    <Helmet title={helmet || header} />
    <div className='flex-auto flex justify-center items-center flex-column'>
      <Header as='h1' textAlign='center'>PLAN</Header>
      <h1 className='f1 f-5-ns fw6 lh-title tc'>{header}</h1>
      <div className='lh-title f3 f2-ns tc measure-narrow'>{content}</div>
    </div>
    <div className='self-end pt3 mt-auto flex'>
      <span>
        Developed by
        {' '}
        <a className='contrast dim' href='https://altomobile.io/' rel='noopener noreferrer' target='_blank'>Altomobile</a>
      </span>
    </div>
  </div>
);

DisplayMessage.propTypes = {
  helmet: PropTypes.string,
  header: PropTypes.node.isRequired,
  content: PropTypes.node,
  className: PropTypes.string,
};
