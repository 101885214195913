import { memo } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Joi from 'joi';
import {
  Form, Message, Input,
} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { Button } from 'site/components/atoms/button/button';
import { Popup } from 'controls';
import { useFieldset, useUrlParams, useAsyncSubmit } from 'controls/hooks';
import { useDispatchCallback } from 'controls/hooks/misc';
import { setNewPassword } from 'site/actions/authentication';
import './resetPassword.less';

const resetForm = {
  email: '', newPassword: '', confirmPassword: '', token: '',
};
const resetSchema = {
  email: Joi.string().label('Email').email({ tlds: { allow: false } }),
  newPassword: Joi.string().label('Password').ruleset.pattern(/^(?=.*[a-z])(?=.*[0-9])(?=.{8,})/).max(30).message('Password needs to have a minimum length of 8, at least one letter and one number.'),
  confirmPassword: Joi.string().label('Confirmation password').required().valid(Joi.ref('newPassword')).messages({ 'any.only': 'Confirmation password must be the same as the password' }),
};

export const ResetPasswordForm = memo(({ location, navigate }) => {
  const { token } = useUrlParams(location.search);
  resetForm.token = token;

  const moveToLogin = () => navigate('/login');

  const { fields: { email, newPassword, confirmPassword }, submit, loading } = useFieldset({ schema: resetSchema, source: resetForm, onSubmit: useAsyncSubmit(useDispatchCallback(setNewPassword), moveToLogin) });

  if (!token) {
    navigate('/login');
  }

  return (
    <div className='form flex flex-column min-vh-100 w-100 pa3 justify-center'>
      <Helmet title='Choose your new password' />
      <div className='form--block center flex-column items-center justify-center self-center'>
        <div className='form__title'>Reset password</div>
        <Form onSubmit={submit} className='reset__form'>
          <h4 className='mb3'> Please provide the following information </h4>
          <Message error header='Error' className='tl' />
          <Form.Field error={email.errored}>
            <label>email</label>
            <Popup message={email.message} enabled={email.errored}>
              <Input fluid autoComplete='email' value={email.value} onChange={email.onChange} />
            </Popup>
          </Form.Field>
          <Form.Field error={newPassword.errored}>
            <label>new password</label>
            <Popup message={newPassword.message} enabled={newPassword.errored}>
              <Input fluid type='password' name='new_password' value={newPassword.value} onChange={newPassword.onChange} autoComplete='new-password' />
            </Popup>
          </Form.Field>
          <Form.Field error={confirmPassword.errored}>
            <label>confirm password</label>
            <Popup message={confirmPassword.message} enabled={confirmPassword.errored}>
              <Input fluid icon='lock' iconPosition='left' type='password' placeholder='Confirm your new password' value={confirmPassword.value} onChange={confirmPassword.onChange} autoComplete='new-password' />
            </Popup>
          </Form.Field>
          <Button color='primary' className='reset__button' disabled={loading} loading={loading}>
            SET NEW PASSWORD
          </Button>
          <Link to='/login' className='reset__link'>Return to sign in</Link>
        </Form>
      </div>
    </div>
  );
});

ResetPasswordForm.propTypes = {
  location: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
};
