import React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'semantic-ui-react';

import './audioTrackSwitcher.less';

const activeTracks = {
  both: {
    instrument: true,
    backing: true,
  },
  instrument: {
    instrument: true,
    backing: false,
  },
  backing: {
    instrument: false,
    backing: true,
  },
};

const getActiveTracksByTrackName = (trackName) => activeTracks[trackName];
function AudioTrackSwitcher({
  audioTrackName,
  isFullScreen,
  onSwitch,
}) {
  const [enabledTracks, setEnabledTracks] = React.useState(getActiveTracksByTrackName(audioTrackName));

  const {
    instrument: isInstrumentTrackEnabled,
    backing: isBackingTrackEnabled,
  } = enabledTracks;

  React.useEffect(() => {
    if (isInstrumentTrackEnabled && isBackingTrackEnabled) {
      onSwitch('both');
      return;
    }

    onSwitch(isInstrumentTrackEnabled ? 'instrument' : 'backing');
  }, [isInstrumentTrackEnabled, isBackingTrackEnabled, onSwitch]);

  React.useEffect(() => {
    const tracks = getActiveTracksByTrackName(audioTrackName);
    setEnabledTracks(tracks);
  }, [audioTrackName]);

  const toggleTrack = (toggledTrackName) => {
    setEnabledTracks((previouslyEnabledTracks) => {
      if (toggledTrackName === 'backing') {
        return {
          instrument: false,
          backing: true,
        };
      }
      if (toggledTrackName === 'instrument') {
        return {
          instrument: true,
          backing: false,
        };
      }
      if (toggledTrackName === 'both') {
        return {
          instrument: true,
          backing: true,
        };

      }

      return {
        ...previouslyEnabledTracks,
        [toggledTrackName]: true,
      };
    });
  };

  return (
    <div className={`track-switcher${isFullScreen ? '__full-screen' : ''}`}>
      <Button
        type='button'
        onClick={() => toggleTrack('both')}
        className={`track-switcher__toggler-button ${isFullScreen ? 'track-switcher__toggler-button--full-screen' : ''} ${audioTrackName === 'both' ? 'track-switcher__toggler-button--selected' : ''}`}
      >
        All tracks
      </Button>
      <Button
        type='button'
        onClick={() => toggleTrack('instrument')}
        className={`track-switcher__toggler-button ${isFullScreen ? 'track-switcher__toggler-button--full-screen' : ''} ${audioTrackName === 'instrument' ? 'track-switcher__toggler-button--selected' : ''}`}
      >
        Mandolin
      </Button>
      <Button
        type='button'
        onClick={() => toggleTrack('backing')}
        className={`track-switcher__toggler-button ${isFullScreen ? 'track-switcher__toggler-button--full-screen' : ''} ${audioTrackName === 'backing' ? 'track-switcher__toggler-button--selected' : ''}`}
      >
        Backing
      </Button>
    </div>
  );
}

AudioTrackSwitcher.propTypes = {
  isFullScreen: PropTypes.bool,
  audioTrackName: PropTypes.oneOf(['instrument', 'backing', 'both']).isRequired,
  onSwitch: PropTypes.func.isRequired,
};

export default React.memo(AudioTrackSwitcher);
