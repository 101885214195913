import React from 'react';
import PropTypes from 'prop-types';
import VerticalLine from './VerticalLine';
import { KanvaImage } from './images';
import Repeater from './Repeater';
import GenericNote from './GenericNote';
import TabBoardDimensionsContext from './TabBoardDimensionsContext';
import { TIME_SIGNATURE_ASSET_HEIGHT_TO_WIDTH_RATIO } from '../constants';
import { TOP_CONTENT_MARGIN } from '../constants/constants';

function Note({
  notes,
  layoutLine,
}) {
  const {
    staffHeight,
    topHeight,
    width: boardWidth,
  } = React.useContext(TabBoardDimensionsContext);

  return (
    <>
      { notes.map((note) => {
        const isFirstLayoutLine = note.location.layoutBreakLineIdx === 1;
        const extraNotesInLine = isFirstLayoutLine ? 2 : 0;
        const notesInLine = note.location.lineNoteTotal + extraNotesInLine;
        const noteSpacing = boardWidth / notesInLine;
        const noteXPos = (
          note.location.layoutBreakNoteIdx
          - 1 // index started from 1
          + extraNotesInLine
        ) * noteSpacing;

        /* all notes */
        if ((note.isRest || note.pitch) && (note?.location?.layoutBreakLineIdx === layoutLine)) {
          return (
            <GenericNote
              key={`generic-note-${note.id}-${note.pitch}`}
              note={note}
              noteXPos={noteXPos}
              noteSpacing={noteSpacing}
            />
          );
        }

        /* measure separation lines */
        if (note.isLine && note?.location?.layoutBreakLineIdx === layoutLine && note?.measure !== 'end' && note.location.firstNoteHasBeenPlayed) {
          return (
            <VerticalLine
              key={`measure-delimiter-${note.id}`}
              height={staffHeight}
              y={topHeight}
              x={noteXPos}
            />
          );
        }

        /* repeaters */
        if (note.direction && note?.location?.layoutBreakLineIdx === layoutLine) {
          // don't paint, next note will paint a double repeater
          if (note.hasForwardRepeaterComing) {
            return;
          }

          return (
            <Repeater
              key={`repeater-${note.id}`}
              direction={note.direction}
              x={noteXPos - (note.direction === 'forward' ? noteSpacing : (-1 * noteSpacing))}
              double={note.hasBackwardRepeaterBehind}
            />
          );
        }

        /* time signature symbols */
        if (note.isSymbol && note.id === 0 && layoutLine === 1) {
          return (
            <KanvaImage
              key={`time-signature-${note.id}`}
              imageSrc='timeSignature.png'
              // three quarters of staff
              height={staffHeight}
              width={staffHeight * TIME_SIGNATURE_ASSET_HEIGHT_TO_WIDTH_RATIO}
              x={noteSpacing}
              // half a quarter of staff as margin
              y={topHeight + TOP_CONTENT_MARGIN}
            />
          );
        }

        return null;
      })}
    </>
  );
}

Note.propTypes = {
  notes: PropTypes.arrayOf(PropTypes.object),
  layoutLine: PropTypes.number,
};

export default Note;
