import React from 'react';
import PropTypes from 'prop-types';
import { Rect } from 'react-konva';

import TabBoardDimensionsContext from './TabBoardDimensionsContext';
import { TAB_LINE_THICKNESS } from '../constants';
import { TOP_CONTENT_MARGIN } from '../constants/constants';

function TabLines({ amount }) {
  const {
    width: boardWidth,
    staffHeight: height,
  } = React.useContext(TabBoardDimensionsContext);

  return (
    <>
      {Array.from({ length: amount }).map((_, i) => (
        <Rect
          key={i.toString()}
          width={boardWidth}
          height={TAB_LINE_THICKNESS}
          x={0}
          y={(height / (amount - 1)) * i + TOP_CONTENT_MARGIN}
          fill='black'
        />
      ))}
    </>
  );
}

TabLines.propTypes = {
  amount: PropTypes.number,
};

TabLines.defaultProps = {
  amount: 4,
};

export default React.memo(TabLines);
