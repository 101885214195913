import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { ErrorPage } from './errorPage';

const b64EncodeUnicode = (str) => btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (match, p1) => String.fromCharCode(`0x${p1}`)));

export class ErrorBoundary extends PureComponent {

  // eslint-disable-next-line react/static-property-placement
  static propTypes = {
    children: PropTypes.node.isRequired,
  }

  // eslint-disable-next-line react/state-in-constructor
  state = { hasError: false };

  componentDidCatch(error, info) {
    this.setState({ hasError: true, error, info });
  }

  render() {
    const { children } = this.props;
    const { hasError, error, info } = this.state;

    if (!hasError) {
      return children;
    }

    const errorInfo = process.env.NODE_ENV === 'production'
      ? b64EncodeUnicode(JSON.stringify({ message: error.message, stack: error.stack, componentStack: info.componentStack }))
      : JSON.stringify({ message: error.message, stack: error.stack, componentStack: info.componentStack }, null, 2);

    return (
      <ErrorPage error={errorInfo} />
    );

  }
}
