import React from 'react';
import PropTypes from 'prop-types';
import { Rect } from 'react-konva';

import TabBoardDimensionsContext from './TabBoardDimensionsContext';
import { NOTE_FILL, VERTICAL_LINE_WIDTH } from '../constants';
import { TOP_CONTENT_MARGIN } from '../constants/constants';

function VerticalLine({ x, y }) {
  const { staffHeight: height } = React.useContext(TabBoardDimensionsContext);
  return (
    <Rect
      width={VERTICAL_LINE_WIDTH}
      height={height}
      x={x}
      y={y + TOP_CONTENT_MARGIN}
      fill={NOTE_FILL}
    />
  );
}

VerticalLine.propTypes = {
  x: PropTypes.number.isRequired,
  y: PropTypes.number,
};

VerticalLine.defaultProps = {
  y: 0,
};

export default React.memo(VerticalLine);
