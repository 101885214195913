import React from 'react';
import { string, node, bool } from 'prop-types';
import { Loader } from 'semantic-ui-react';
import './button.less';

export const Button = ({
  children, loading = false, className, color, outline, type, size, width, noBorder, ...props
}) => (
  // eslint-disable-next-line react/button-has-type
  <button
    className={
      `button button__${color}
      ${outline ? `button__outline--${color}` : ''}
      ${size ? `button--${size}` : ''}
      ${type ? `button__${type}__${color}` : ''}
      ${width ? `button__width--${width}` : ''}
      ${noBorder ? 'button--no-border' : ''}
      ${className || ''}`
    }
    {...props}
  >
    {loading
      ? (
        <Loader active inline />
      )
      : children}
  </button>
);

Button.propTypes = {
  children: node,
  loading: bool,
  className: string,
  color: string,
  outline: bool,
  size: string,
  type: string,
  width: string,
  noBorder: bool,
};
