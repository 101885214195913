/* eslint-disable jsx-a11y/media-has-caption */
import {
  memo, useCallback, useEffect, useRef, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import './videoPlayer.less';

import { useEffectAsync, useScreenSize } from 'controls/hooks/misc';
import { useFullScreenStatus } from 'controls/hooks';
// eslint-disable-next-line import/no-cycle
import { getSong } from 'site/actions/songs';
import {
  Dropdown, Progress, Loader, Icon,
} from 'semantic-ui-react';
import { ReactHlsPlayer } from 'controls/hlsPlayer/';
import { isEmpty } from 'site/utils';
import { CustomIcon } from 'site/components/atoms/icons';
import { TabBoardDimensionsContextProvider } from './forms/TabBoardDimensionsContext';
import { TabStage } from './forms';
import {
  getBrowserData, getSongTime, setExactTimeout, useHandleProgressBar,
} from './videoPlayerUtils';
import { NoVideoScreen } from './noVideoScreen/noVideoScreen';
import {
  NORMAL_MODE, SPEED_OPTIONS,
} from './constants';
import AudioTrackSwitcher from './audioTrackSwitcher';

/**
 * @typedef {"instrument" | "backing" | "both"} AudioTrackName
 */

export const VideoPlayer = memo(({
  params, modeIndex, setModeIndex, song, setSong, setLoading,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isVideoLoading, setIsVideoLoading] = useState(false);
  const [isInstrumentTrackLoading, setIsInstrumentTrackLoading] = useState(false);
  const [isBackingTrackLoading, setIsBackingTrackLoading] = useState(false);
  const [hasVideo, setHasVideo] = useState(false);
  const [hasBackingTrack, setHasBackingTrack] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [notes, setNotes] = useState([]);
  const [repeaters, setRepeaters] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  /**
   * @type {[AudioTrackName, Function]}
   */
  const [audioTrackName, setAudioTrackName] = useState('instrument');

  /* 0 => Normal Speed
   * 1 => Half Speed */
  const willPaintNextNote = useRef();
  const getNotesTimer = useRef();
  const vidRef = useRef();
  const currentNote = useRef();
  const audioRef = useRef();
  const backingAudioRef = useRef();
  const progressRef = useRef();
  const [songTime, setSongTime] = useState(0);
  const [isBuffered, setIsBuffered] = useState(true);
  const [barProgress, setBarProgress] = useState(0);
  const [layoutLine, setLayoutLine] = useState(1);
  const [isVideoReadyToPlay, setIsVideoReadyToPlay] = useState(false);
  const [deviceData] = useState(getBrowserData());
  const [m3u8Source, setM3u8Source] = useState([]);
  const [isMuted, setIsMuted] = useState(false);
  /* sometimes when toggling speed player doesn't change the isVideoReadyToPlay back to
  true. With this flag we tell the player that video is already cached. Subsequent
  rerenders will toggle isVideoReadyToPlay to true
   */
  const cacheRef = useRef();
  const bufferCheckInterval = useRef();
  const isSwitchingTracks = useRef();
  const { isMobile } = useScreenSize();

  useEffectAsync(async () => {
    const loadedSong = await dispatch(getSong(params.songId, params.songName, navigate));
    if (!loadedSong.tracks) return setLoading(false);
    const loadedSongNotes = loadedSong.tracks[modeIndex].chartFile.chartNotes;
    const loadedSongRepeaters = loadedSong.tracks[modeIndex].chartFile.repeaters;

    if (!isEmpty(loadedSong)) {
      setSong(loadedSong);
      setNotes(loadedSongNotes);
      setRepeaters(loadedSongRepeaters);
      setSongTime(getSongTime(loadedSongNotes, loadedSongRepeaters));
      const loadedSongHasVideo = !!loadedSong.tracks[modeIndex].videoFile?.url;
      setHasVideo(loadedSongHasVideo);
      setIsVideoReadyToPlay(!loadedSongHasVideo);
      setIsVideoLoading(true);
      if (isEmpty(loadedSong.tracks[modeIndex].backingAudioFile)) {
        setIsBackingTrackLoading(false);
      }
      if (!isEmpty(loadedSong.tracks[modeIndex].bothFile)) {
        setAudioTrackName('both');
      }
    } else {
      setNotFound(true);
    }
  }, []);

  useEffect(() => {
    if (!song?.tracks) {
      return;
    }

    const hlsSource = song.tracks[modeIndex].masterFile?.url;
    if (hlsSource) setM3u8Source(hlsSource);
  }, [modeIndex, song.tracks]);

  /* This fn sets the offset of the notes. Offset will display the notes
  accordingly to its layoutBreakLineIdx.
  offset 0 is first page, next display will be ~size.width x 2,
  next ~size.width x 3 and so on */
  const updateNotesOnScreen = useCallback((note) => {
    if (note.location.layoutBreakLineIdx) {
      setLayoutLine(note?.location?.layoutBreakLineIdx);
    }
  }, []);

  const handleSongFinished = useCallback(() => {
    setIsPlaying(false);
  }, []);

  const highlightNote = useCallback((note, noteId) => {
    // maps due to the fact that notes are stored in an array
    // in case of multiple notes on the same note array (a chord)
    const highlightedNote = note.map((oldNote) => (
      { ...oldNote, color: 'black', show: true }
    ));

    const songWithUpdatedHighlightedNote = [
      ...song.tracks[modeIndex].chartFile.chartNotes.slice(0, noteId),
      highlightedNote,
      ...song.tracks[modeIndex].chartFile.chartNotes.slice(noteId + 1),
    ];

    if (!note[0]?.isRest) {
      setNotes(songWithUpdatedHighlightedNote);
    }
  }, [modeIndex, song.tracks]);

  const paintNote = useCallback(async (noteIdx = 0, currentTime = 0) => {
    currentNote.current = noteIdx;

    const mappedSong = repeaters.length > 0 ? repeaters[repeaters.length - 1] : notes;
    let note = mappedSong[noteIdx];
    let stop = false;

    // check whether it's a musical note and not any other song symbol
    if (!note[0]?.isLine && !note[0]?.repeater && !note[0].isSymbol) {
      // check whether the song has repeaters
      if (noteIdx < notes.length && repeaters.length > 0) {
        // if the next to be painted note id doesn't match the index of the notes
        // array, it means that we are repeating that part of the song
        // so we need to update the screen accordingly
        if (notes[noteIdx][0].id !== note[0].id || noteIdx > notes.length - 1) {
          updateNotesOnScreen(note[0]);

          const musicNoteId = note[0].id;
          const musicNoteToHighlight = notes[musicNoteId];
          highlightNote(musicNoteToHighlight, musicNoteId);

          stop = true;
        }
      }
      if (repeaters.length > 0) {
        stop = true;

        const { id } = note[0];
        note = notes[id];

        updateNotesOnScreen(note[0]);
        highlightNote(note, id);
      }
      if (!stop || repeaters.length === 0) {
        highlightNote(note, noteIdx);
      }
    }

    if (noteIdx === mappedSong.length - 1) {
      handleSongFinished();
      return;
    }

    if (repeaters.length === 0) {
      updateNotesOnScreen(note[0]);
    }

    const playingRef = !hasVideo ? audioRef : vidRef;
    const canPaintNextNote = (((isBuffered || isVideoReadyToPlay || cacheRef.current) && willPaintNextNote.current !== false) || isPlaying);

    const calculateNextNoteTime = (nextNoteIdx /* id */, time /* currentTime */) => {
      // the difference between the time of the next note and the current time
      let playingTimeOffset;
      let nextNotePlayTime = time;
      let currentInterval;
      const intervalCallback = () => paintNote(nextNoteIdx + 1, 0, 0);

      if (nextNoteIdx >= 0) {
        if (nextNotePlayTime <= 0) {
          // if it's the first note we don't have to calculate the playingTimeOffset
          nextNotePlayTime = mappedSong[nextNoteIdx + 1][0].time * 1000;
        }

        // the 1000 multiplier is because the time is in seconds and we need it in milliseconds
        const currentPlayingTime = playingRef.current.currentTime * 1000;
        playingTimeOffset = nextNotePlayTime - currentPlayingTime;

        currentInterval = setExactTimeout(intervalCallback, playingTimeOffset, 1, canPaintNextNote);
        getNotesTimer.current = currentInterval;

      } else {
        // (fer): is this really ever called? might need to remove it in the future
        playingTimeOffset = mappedSong[nextNoteIdx + 1][0].time * 1000 - 10;

        currentInterval = setExactTimeout(intervalCallback, playingTimeOffset, 1, canPaintNextNote);
        getNotesTimer.current = currentInterval;
      }
    };

    calculateNextNoteTime(noteIdx, currentTime);
  }, [handleSongFinished, highlightNote, notes, updateNotesOnScreen, repeaters, hasVideo, isBuffered, isVideoReadyToPlay, isPlaying]);

  const stopNotes = useCallback((willResumeLater = false) => {
    clearTimeout(getNotesTimer.current);
    setIsPlaying(willResumeLater);
    willPaintNextNote.current = false;
  }, []);

  const updatePlayer = useCallback(async (newModeIndex, updatedNote = null, updatedNoteIdx = 0) => {
    // we pass the note and the note idx to the currentNoteRef
    // which later will be user for playPause method to play the note
    // since updatedNote.id is not always the same as updatedNoteIdx
    // that usually happens when the song has been expanded due to
    // the repeaters
    const newModeNotes = song.tracks[newModeIndex].chartFile.chartNotes;
    const reps = song.tracks[newModeIndex].chartFile.repeaters;
    const currentTime = !updatedNote ? 0 : updatedNote[0].time;

    stopNotes();

    currentNote.current = !updatedNote ? 0 : updatedNoteIdx;

    if (!hasVideo) {
      audioRef.current.pause();
      audioRef.current.load();
      if (backingAudioRef.current) {
        backingAudioRef.current.pause();
        backingAudioRef.current.load();
      }
    }

    if (vidRef.current) {
      vidRef.current.pause();
      vidRef.current.currentTime = currentTime;
    }

    // check if there is video file
    if (!song.tracks[newModeIndex].videoFile?.url) {
      setHasVideo(false);
      setIsInstrumentTrackLoading(true);
    } else {
      setHasVideo(true);
    }

    if (!updatedNote) {
      // when player is being restarted
      // start from first note = chartNotes[0][0]
      updateNotesOnScreen(song.tracks[newModeIndex].chartFile.chartNotes[0][0]);
      setNotes(song.tracks[newModeIndex].chartFile.chartNotes);
      setBarProgress(0);
    } else {
      highlightNote(updatedNote, updatedNoteIdx);
    }

    const newSongTime = getSongTime(newModeNotes, reps);
    setSongTime(newSongTime);
    setIsPlaying(false);
    setModeIndex(newModeIndex);
    setRepeaters(reps);
  }, [song.tracks, hasVideo, setModeIndex, updateNotesOnScreen, stopNotes, setBarProgress, highlightNote]);

  const {
    barOnClick, startDrag, stopDrag, isDragging,
  } = useHandleProgressBar(updateNotesOnScreen, setBarProgress, updatePlayer, modeIndex, repeaters, notes, songTime);

  /**
   * @param {Boolean} willResumeLater
   *   Used when we need to pause note rendering temporarily
   *   Example: when HLS video is waiting for audio after switching tracks.
   */

  const playPause = useCallback(() => {
    const playingRef = !hasVideo ? audioRef : vidRef;

    if (!isPlaying) {
      /* offsets delay, mainly when there is no cache */
      let delayOffset = 0;
      setIsPlaying(true);
      willPaintNextNote.current = true;

      if (!hasVideo) {
        delayOffset = audioRef.current.currentTime === 0 ? 0.1 : 0;
        audioRef.current.play(delayOffset);

        if (backingAudioRef.current) {
          /* usually backing lags behind main track
          that's why the offset is positive */
          backingAudioRef.current.play(audioRef.current.currentTime + delayOffset);
        }
      }

      if (vidRef.current) {
        vidRef.current.play(vidRef.current.currentTime);
      }

      paintNote(currentNote.current, playingRef.current.currentTime);
      /* if song is not playing */
    } else {
      playingRef.current.pause();

      if (backingAudioRef.current && !hasVideo) {
        backingAudioRef.current.pause();
      }
      // simulate ghost notes
      stopNotes();
    }
  }, [isPlaying, paintNote, hasVideo, stopNotes]);

  const resumeNotes = (noteId = null, currentTime = 0) => {
    const mediaElement = vidRef.current || audioRef.current;
    paintNote(noteId || currentNote.current, currentTime || mediaElement.currentTime);
  };

  /* should check buffer each second once it has
  detected that network is not keeping up with the
  song download, progress is not reliable for updating
  is buffer states https://github.com/video-dev/hls.js/issues/4028 */
  const keepCheckingBuffer = useCallback(
    () => {
      const targetRef = !hasVideo ? audioRef.current : vidRef.current;
      // clear existing interval, if any
      clearInterval(bufferCheckInterval.current);

      /* more info HAVE_FUTURE_DATA https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/readyState */
      if (targetRef.readyState <= targetRef.HAVE_FUTURE_DATA) {
        bufferCheckInterval.current = setInterval(() => {

          if (targetRef.readyState > targetRef.HAVE_FUTURE_DATA) {
            setIsBuffered(true);
            setIsVideoReadyToPlay(true);
            cacheRef.current = true; // let user see play btn in controls
          }
        }, 1000);
      }
    },

    [audioRef, vidRef, hasVideo],
  );

  useEffect(() => {
    // 1. listen to buffer allocation in browser and allow user to keep playing
    // with enough buffer size
    // 2. set space key as play-pause trigger

    // listener callbacks
    const handleProgressBuffer = (e) => {
      if (bufferCheckInterval.current) {
        // interval is set, check if it can be cleared
        keepCheckingBuffer();
      }

      if (e.target.readyState > e.target.HAVE_FUTURE_DATA) {
        if (!isPlaying) {
          setIsBuffered(true);
          setIsVideoReadyToPlay(true);
        }
      } else if (!isSwitchingTracks.current) {
        /* firefox and safari might stop song when toggling audios
        due to a lack of HAVE_FUTURE_DATA so if the toggling was just done
        dont let it stop the song and check next render if HAVE_FUTURE_DATA
        is enough */
        setIsBuffered(false);
        setIsVideoReadyToPlay(false);
        if (isPlaying) {
          keepCheckingBuffer();
          willPaintNextNote.current = false;
        }
      }
    };

    const handleKeyPress = (e) => {
      if (e.code === 'Space' && (!hasVideo ? (isBuffered && !isInstrumentTrackLoading) : (isVideoReadyToPlay && !isVideoLoading))) {
        playPause();
      }
    };

    // store ref for unmount
    const targetRef = !hasVideo ? audioRef.current : vidRef.current;
    if (targetRef) {

      window.addEventListener('keydown', handleKeyPress);
      targetRef.addEventListener('progress', handleProgressBuffer);
      // unmount
      return () => {
        targetRef.removeEventListener('progress', handleProgressBuffer);
        window.removeEventListener('keydown', handleKeyPress);
      };
    }
  }, [notes, hasVideo, isBuffered, isVideoReadyToPlay, playPause, isPlaying, keepCheckingBuffer, isVideoLoading, isInstrumentTrackLoading]);

  const handleAudioTrackSwitch = useCallback((newAudioTrackName) => {
    setAudioTrackName(newAudioTrackName);
  }, []);

  const handleAudioTrackSwitching = () => {
    isSwitchingTracks.current = true;
  };

  const handleAudioTrackSwitched = () => {
    isSwitchingTracks.current = false;
  };

  /**
   * According to MDN:
   * > The waiting event is fired when playback has stopped because of a
   *   temporary lack of data.
   *
   * This tends to happen when the audio track is switched and a new request is
   * made to get the new audio track and it goes over a certain time threshold.
   */
  const handleVideoWaiting = () => {
    stopNotes(true);
    setIsBuffered(false);
    setIsVideoReadyToPlay(false);
    keepCheckingBuffer();
    cacheRef.current = undefined; // avoid displaying play btn in controls
  };

  const handleVideoLoaded = () => {
    cacheRef.current = modeIndex;
    setIsVideoLoading(false);
    setIsVideoReadyToPlay(true);
    setIsBuffered(true);
    keepCheckingBuffer();
    // resume notes
    if (isPlaying) {
      resumeNotes();
    }
    willPaintNextNote.current = true;
  };

  const handleAudioProgressUpdate = () => {
    if (hasVideo) {
      // let video update progress bar
      return;
    }
    setBarProgress((audioRef?.current?.currentTime / songTime) * 100);
  };

  const isAudioTrackNeededAndLoading = useCallback((trackName) => {
    if (audioTrackName === 'both') {
      return isInstrumentTrackLoading || isBackingTrackLoading;
    }

    const isAudioTrackNeeded = ['both', trackName].includes(audioTrackName);
    const isAudioTrackLoading = trackName === 'instrument' ? isInstrumentTrackLoading : isBackingTrackLoading;
    return isAudioTrackNeeded && isAudioTrackLoading;
  }, [isBackingTrackLoading, isInstrumentTrackLoading, audioTrackName]);

  const videoPlayerElement = useRef(null);

  let isFullScreenSupported;
  let isFullScreen;
  let openFullScreen;
  let setIsFullScreen;

  try {
    [isFullScreenSupported, isFullScreen, openFullScreen, setIsFullScreen] = useFullScreenStatus(videoPlayerElement);
  } catch (e) {
    isFullScreen = false;
    isFullScreenSupported = false;
  }

  const closeFullScreen = () => {
    if (getBrowserData().name !== 'Safari') document.exitFullscreen();
    else document.webkitExitFullscreen();
    setIsFullScreen(false);
  };

  const handleVideoTimeUpdate = useCallback(() => {
    /* <video /> behaves weirdly when it's waiting
     * avoid setting progress in that case */
    if (typeof cacheRef.current === 'undefined') return;
    setBarProgress((vidRef?.current?.currentTime / songTime) * 100);
  }, [cacheRef, vidRef, songTime, setBarProgress]);

  const playTimeControls = useCallback(() => {
    const playingTime = {
      minutes: '00',
      seconds: '00',
    };

    const getTotalTime = () => {
      if (!vidRef.current) return { playingTime, totalSongTime: { minutes: '00', seconds: '00' } };

      const minutes = `0${Math.floor(vidRef.current.duration / 60).toString()}`.slice(-2);
      const seconds = `0${Math.floor(vidRef.current.duration % 60).toString()}`.slice(-2);
      return { minutes, seconds };
    };

    const totalSongTime = getTotalTime();

    if (vidRef.current) {
      playingTime.minutes = `0${Math.floor(vidRef.current.currentTime / 60).toString()}`.slice(-2);
      playingTime.seconds = `0${Math.floor(vidRef.current.currentTime % 60).toString()}`.slice(-2);
    }

    return { playingTime, totalSongTime };
  }, [vidRef]);

  const { playingTime, totalSongTime } = playTimeControls();

  return (
    <div className={`video-player ${isFullScreen ? 'video-player--full-screen' : ''} w-100 h-100`} ref={videoPlayerElement}>
      <div className={`video-player__content ${isFullScreen ? 'video-player__content--bordered' : ''}`}>
        { song.tracks && (
        <div className='video-player__tab-bar'>
          {song?.tracks && (
            <TabBoardDimensionsContextProvider parentRef={videoPlayerElement}>
              <TabStage notes={notes} layoutLine={layoutLine} isFullScreen={isFullScreen} />
            </TabBoardDimensionsContextProvider>
          )}
          {notes.length > 0
            && (
              <div
                className='video-player__progress-bar pb1'
                ref={progressRef}
                onClick={(event) => barOnClick(event)}
                onMouseDown={(event) => startDrag(event)}
                onMouseUp={() => stopDrag()}
                onMouseOut={(() => stopDrag())}
                onBlur={(() => stopDrag())}
              >
                <Progress
                  ref={progressRef}
                  percent={barProgress}
                  color='orange'
                  className='no-hover'
                  active={isDragging}
                />
              </div>
            )}
          <div className={`video-player__control-bar ${deviceData.name}${deviceData.version}`}>
            <div className='video-player__control-bar--track'>
              <div className='video-player__controls'>
                {/* Check media buffering */}
                {
                (hasVideo
                  // check hls
                  ? (isVideoReadyToPlay && !isVideoLoading) || cacheRef?.current === modeIndex
                  // check html audios
                  : (isBuffered && !isAudioTrackNeededAndLoading('backing') && !isAudioTrackNeededAndLoading('instrument'))
                ) ? (
                  <CustomIcon
                    name={isPlaying ? 'pause-video' : 'play-video'}
                    className='play-pause-icons'
                    onClick={() => playPause()}
                  />
                  ) : (
                    <Loader size='small' inline active />
                  )
              }
                <CustomIcon name='replay-video' className='restart-icon' onClick={() => updatePlayer(modeIndex)} />
                <span>
                  {isVideoReadyToPlay && <span className='video-player__control-bar--time'>{`${playingTime.minutes}:${playingTime.seconds}${isMobile ? ' ' : ' / '}${totalSongTime.minutes}:${totalSongTime.seconds}`}</span>}
                  <Icon name={isMuted ? 'volume off' : 'volume up'} className='volume-icon pl2 text__gray' onClick={() => setIsMuted(!isMuted)} />
                  {!isMobile && <span className='dark-red'>{isMuted ? 'muted' : ''}</span>}
                </span>
              </div>
              <div className='video-player__media-controls'>
                <div className='video-player__audio-options'>
                  {((!isEmpty(song.tracks[modeIndex]?.backingAudioFile) && !hasVideo)
                    || (hasVideo && hasBackingTrack))
                    && (
                      <AudioTrackSwitcher
                        audioTrackName={audioTrackName}
                        onSwitch={handleAudioTrackSwitch}
                        isFullScreen={isFullScreen}
                      />
                    )}
                  <Dropdown
                    button
                    className={`video-player__speed-picker ${isFullScreen ? 'video-player__speed-picker--full-screen' : ''} icon`}
                    floating
                    icon='dropdown'
                    options={song?.tracks?.length === 2 ? SPEED_OPTIONS : [SPEED_OPTIONS[NORMAL_MODE]]}
                    onChange={(event, { value: newModeIndex }) => updatePlayer(newModeIndex)}
                    text={SPEED_OPTIONS[modeIndex].text}
                  />
                </div>
                {isFullScreenSupported && (
                  <div className='video-player__full-screen'>
                    <Icon
                      className='pointer text__gray'
                      name={!isFullScreen ? 'expand' : 'compress'}
                      onClick={!isFullScreen ? openFullScreen : closeFullScreen}
                      size='big'
                    />
                  </div>
                )}
              </div>
            </div>
            <div className='video-player__control-bar--info'>
              <p>
                Key:
                {' '}
                <span>{song?.key}</span>
                {' '}
              </p>
              <p className='pl3'>
                BPM:
                {' '}
                <span>{song?.tracks ? song?.tracks[modeIndex]?.bpm : <>&nbsp;</>}</span>
              </p>
            </div>
          </div>
        </div>
        )}

        {song?.tracks && !hasVideo && (
        <audio
          playsInline
          preload='auto'
          ref={audioRef}
          onLoadStart={() => setIsInstrumentTrackLoading(true)}
          onLoadedMetadata={() => setIsInstrumentTrackLoading(false)}
          onTimeUpdate={handleAudioProgressUpdate}
          muted={audioTrackName === 'backing'}
        >
          <source
            src={song.tracks[modeIndex]?.audioFile.url}
          />
          Your browser does not support the audio element.
        </audio>
      )}

        {song?.tracks && song.tracks[modeIndex]?.backingAudioFile?.url && !hasVideo && (
        <audio
          playsInline
          preload='auto'
          ref={backingAudioRef}
          onLoadStart={() => setIsBackingTrackLoading(true)}
          onLoadedMetadata={() => setIsBackingTrackLoading(false)}
          muted={audioTrackName === 'instrument'}
        >
          <source src={song.tracks[modeIndex]?.backingAudioFile.url} />
          Your browser does not support the audio element.
        </audio>
      )}

        <div className='video-player__video-container'>
          {(hasVideo) && (
          <ReactHlsPlayer
            src={m3u8Source}
            playsInline
            preload='auto'
            playerRef={vidRef}
            id='video'
            className='video-player__video'
            hidden={isVideoLoading}
            onLoadStart={() => setIsVideoLoading(true)}
            onLoadedMetadata={deviceData.isIOS ? handleVideoLoaded : undefined}
            onCanPlayThrough={handleVideoLoaded}
            onTrackSwitching={handleAudioTrackSwitching}
            onTrackSwitched={handleAudioTrackSwitched}
            onTimeUpdate={handleVideoTimeUpdate}
            onWaiting={handleVideoWaiting}
            width='100%'
            controls={false}
            autoPlay={false}
            trackName={audioTrackName}
            setHasBackingTrack={setHasBackingTrack}
            isVideoLoading={isVideoLoading}
            muted={isMuted}
          />
          )}

          {!notFound && !hasVideo && <NoVideoScreen customMessage={song.title || 'Loading song'} />}
          {hasVideo && isVideoLoading && <NoVideoScreen customMessage='Loading video' />}
          {notFound && <NoVideoScreen customMessage='Track not found' />}
        </div>
      </div>
    </div>
  );
});

VideoPlayer.propTypes = {
  params: PropTypes.object.isRequired,
  song: PropTypes.object.isRequired,
  setSong: PropTypes.func.isRequired,
  modeIndex: PropTypes.number.isRequired,
  setModeIndex: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
};
