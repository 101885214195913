import PropTypes from 'prop-types';
import { Dimmer, Loader } from 'semantic-ui-react';

export const DimmerLoader = ({ message }) => (
  <Dimmer active inverted>
    <Loader inverted>{message}</Loader>
  </Dimmer>
);

DimmerLoader.propTypes = {
  message: PropTypes.string,
};

export const InlineLoader = () => <Loader active inline />;
