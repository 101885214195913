import { combineReducers } from 'redux';
import { songReducer, featuredSongsReducer } from './songs';
import { cleanWhenNoUser } from './utils';
import { siteInfoInfoReducer } from './siteReducer';
import { keysReducer } from './keys';

const mainReducer = combineReducers({
  errorMessage: (state = false, action) => (action.type === 'SET_ERRORED' ? action.message : state),
  userInfo: (state = null, action) => (action.type === 'SET_USER' ? action.user : state),
  keys: keysReducer,
  songs: cleanWhenNoUser(songReducer),
  featuredSongs: cleanWhenNoUser(featuredSongsReducer),
  siteInfo: siteInfoInfoReducer,
});

export const rootReducer = (state, action) => mainReducer(action.type === 'SET_ERRORED' ? undefined : state, action);
