/* eslint-disable no-const-assign */
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// import { useParams, useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
// import {
// Icon, Image, Accordion,
// } from 'semantic-ui-react';
import { Helmet } from 'react-helmet';

import { VideoPlayer } from 'site/components/videoPlayer';
import { Container } from 'site/components/layout/container';
// import { Button } from 'site/components/atoms/button/button';
import { setBackground } from 'site/actions/background';
import { UnAllowed } from './unallowed';

import './song.less';

export const SongPage = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const secondaryBackground = useSelector((state) => state.siteInfo.secondaryBackground);
  const userInfo = useSelector((state) => state.userInfo);
  const [modeIndex, setModeIndex] = useState(0);
  const [song, setSong] = useState({});
  const [loading, setLoading] = useState(true);
  // const [lyricsVisibility, setLyricsVisibility] = useState(false);
  // const HasLyricsOrChords = (song.lyrics || song.chords);
  // const location = useLocation();
  // const hasNoLayout = location.pathname.split('/')[1] === 'song-no-layout';

  useEffect(() => {
    if (song === 401 || song.tracks) setLoading(false);
    if (!song.tracks && !loading && !secondaryBackground) {
      dispatch(setBackground(true));
    }
  }, [song, modeIndex, secondaryBackground, dispatch, loading]);

  return (
    <div className='song'>
      <Helmet title={`${song?.title || 'Loading song...'} | ${window.__ENVIRONMENT__.APP_NAME}`} />
      <Container>
        {userInfo || !_.isEmpty(song) || loading
          ? (
            <>
              <div className='song__hero'>
                <div className='song__video'>
                  {song?.tracks
                    && (
                      <div className='song__video__header'>
                        <h1>{song?.title}</h1>
                      </div>
                    )}
                  <div className='song__video-player'>
                    <VideoPlayer params={params} modeIndex={modeIndex} setModeIndex={setModeIndex} song={song} setSong={setSong} setLoading={setLoading} />
                  </div>
                </div>
              </div>
              {/*
              {!hasNoLayout
                && (
                  <div>
                    <div className='song__info'>
                      {song?.description && (
                      <p>{song?.description}</p>
                )}

                      {HasLyricsOrChords && (
                      <Accordion>
                        <Accordion.Title
                          active={lyricsVisibility}
                          index={0}
                          onClick={() => setLyricsVisibility(!lyricsVisibility)}
                        >
                          <Icon name='dropdown' />
                          <span className='underline'>
                            Show lyrics and chords
                          </span>
                        </Accordion.Title>
                        <Accordion.Content active={lyricsVisibility}>
                          {song?.lyrics && (
                          <>
                            <span>Lyrics:</span>
                            <pre>{song?.lyrics}</pre>
                          </>
                      )}
                          {song?.chords && (
                          <>
                            <span>Chords:</span>
                            <pre>{song.chords}</pre>
                          </>
                        )}
                        </Accordion.Content>
                      </Accordion>
                      )}
                    </div>
                  </div>
                )}
              {song?.pdfThumbnail?.url && !hasNoLayout && (
              <div className='song__pdf-preview pt4'>
                {song?.tablatureFile?.url && (
                  <a href={song?.tablatureFile?.url} target='_blank' rel='noopener noreferrer' className='pdf-preview__download'>
                    <Button className='song__pdf-preview--button' size='small' color='primary'>
                      Download PDF
                    </Button>
                  </a>
                )}
                <Image src={song?.pdfThumbnail?.url} alt='song' className='song__image pb5' />
              </div>
              )}
              */}
            </>
          )
          : <UnAllowed />}
      </Container>
      {/* Uncomment when necessary
      {{userInfo
        && (
          <div className='song__related__container'>
            <div className='song__related'>
              <div className='song__related__item'>
                <iframe width='100%' height='100%' src='https://www.youtube.com/embed/cUxRhesT8gY?start=1' title='YouTube video player' frameBorder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowFullScreen />
              </div>
              <div className='song__related__item'>
                <iframe width='100%' height='100%' src='https://www.youtube.com/embed/lgAQ_KnLN_A?start=1' title='YouTube video player' frameBorder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowFullScreen />
              </div>
              <div className='song__related__item'>
                <iframe width='100%' height='100%' src='https://www.youtube.com/embed/_Ie1ban6-sk' title='YouTube video player' frameBorder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowFullScreen />
              </div>
            </div>
          </div>
        )}} */}
    </div>
  );
};
