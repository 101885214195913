import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Loader } from 'semantic-ui-react';
import { useIsMounted } from 'controls/hooks/misc';

export const Spinner = ({ ...props }) => {
  const [time, setTime] = useState(0);
  const mounted = useIsMounted();
  const location = useLocation();
  const { initializing } = useSelector((state) => state.siteInfo);
  const filterWasApplied = location.search;

  setTimeout(() => {
    if (mounted.current && !initializing) {
      setTime(true);
    }
  }, 10000);

  return (
    <>
      {!time && mounted.current
        ? (
          <Loader active inline {...props} />
        )
        : (
          <p>
            {filterWasApplied
              ? 'There is no result for your search'
              : 'An error has occurred. Check your internet connection and refresh the page'}
          </p>
        )}
    </>
  );
};
