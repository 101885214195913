import _ from 'lodash';
import { Children, Fragment } from 'react';
import { parseToPredicate } from 'search-parser';
import fileDialog from 'file-dialog';
import moment from 'moment';

const makeFilterable = (propName, columns) => (item) => {
  item[propName] = item[propName]
    ? item[propName]
    : _.transform(columns, (result, { value }, idx) => {
      result[idx] = normalizeStr(value({ rowData: item }));
    }, {});
  return item;
};
const filterWithProp = (propName) => (item, textToSearch, keyword) => {
  if (keyword === 'freetext') {
    return _(item[propName]).values().some((value) => _.includes(value, textToSearch));
  }
  return _.includes(item[propName][keyword], textToSearch);
};

export const sortByProperty = (property) => (a, b) => {
  if (a[property] > b[property]) return 1;
  if (a[property] < b[property]) return -1;
  return 0;
};
export const normalizeStr = _.flow(_.trim, _.toLower, _.deburr);
export const flattenReactChildren = (children) => _.flatten(Children.map(children, (child) => (_.get(child, 'type') === Fragment ? flattenReactChildren(child.props.children) : child)));
export const formatDate = (date, format = 'DD-MMM-YYYY HH:mm') => moment(date).format(format);
export const formatDuration = (milliseconds) => moment.utc(milliseconds).format('mm:ss');
export const delay = (ms) => new Promise((res) => setTimeout(res, ms));
export const getValue = (value, mapping = {}, defaultValue) => _.get(mapping, [value], defaultValue);
export const selectFiles = async ({ multiple = false, accept = '*' } = {}) => fileDialog({ accept, multiple });
export const filterObjects = (source, columns, showTotals, itemsPerPage = 1, {
  sortSearchParams, sortDir, sortBy, preserveCache = true,
}) => {

  let items = 1;
  const sortByName = _.get(columns[sortBy], 'valueRaw');
  const metaProp = '__filterInfo';
  const searchFn = filterWithProp(metaProp);
  const createMetadata = makeFilterable(metaProp, columns);
  const filterParams = _.omit(sortSearchParams, ['sortDir', 'page', 'sortBy']);

  let results = _(source).map(createMetadata);

  _.forEach(filterParams, (value) => {
    if (value) {
      results = results.filter(parseToPredicate(_.replace(normalizeStr(value), /[()]/g, ''), searchFn));
    }
  });

  if (sortByName || sortDir) {
    results = results.orderBy((item) => _.get(item, sortByName, _.get(item[metaProp], sortBy)), sortDir);
  }

  items = Math.ceil(results.value().length / (showTotals ? itemsPerPage : 1));
  if (showTotals) {
    if (_.size(getItemsBatch(results.value(), Number(sortSearchParams.page), itemsPerPage)) < 1) {
      results = getItemsBatch(results, 1, itemsPerPage);

    } else {
      results = getItemsBatch(results, Number(sortSearchParams.page), itemsPerPage);
    }
  }

  results = results.value();

  if (!preserveCache) {
    _.each(source, (item) => (delete item[metaProp], item));
  }

  return [results, items];
};

export const getItemsBatch = (items, index, batchSize) => (items.slice((index - 1) * batchSize, index * batchSize));
export const isEmpty = (obj) => [Object, Array].includes((obj || {}).constructor) && !Object.entries((obj || {})).length;
