import React from 'react';
import PropTypes from 'prop-types';

import { Group } from 'react-konva';

import { VERTICAL_LINE_WIDTH } from '../constants';

import VerticalLine from './VerticalLine';
import TabLetters from './TabLetters';
import TabLines from './TabLines';

import TabBoardDimensionsContext from './TabBoardDimensionsContext';

function TabStaff({ layoutLine }) {
  const {
    staffHeight,
    topHeight: yOffset,
    width,
  } = React.useContext(TabBoardDimensionsContext);

  return (
    <Group y={yOffset}>
      <TabLines />
      {layoutLine === 1 && <TabLetters />}
      <VerticalLine height={staffHeight} x={0} />
      <VerticalLine height={staffHeight} x={width - VERTICAL_LINE_WIDTH} />
    </Group>
  );
}

TabStaff.propTypes = {
  layoutLine: PropTypes.number.isRequired,
};

export default TabStaff;
