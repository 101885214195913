import React from 'react';
import PropTypes from 'prop-types';

import { Line } from 'react-konva';

function Pick({
  color,
  height,
  x,
  y,
}) {
  const ratio = height / 20;

  const points = [
    33 * ratio,
    -5 * ratio,
    11 * ratio,
    -5 * ratio,
    22 * ratio,
    17.5 * ratio,
  ];

  return (
    <Line
      points={points}
      x={x}
      y={y}
      stroke={color}
      fill={color}
      closed
      tension={0.5}
    />
  );
}

Pick.propTypes = {
  color: PropTypes.oneOf(['white', 'black']).isRequired,
  height: PropTypes.number.isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
};

export default React.memo(Pick);
