export const useQueryStyle = (width, type) => {
  const smallScreen = 1081;
  const mediumScreen = 1115;
  const bigScreen = 2499;
  const mobile = 426;
  if (width && type) {
    if (type === 'style') {
      if (width < bigScreen) {
        if (width < mediumScreen) {
          if (width < mobile) {
            return `${6.5}rem`;
          }
          return `${9}rem`;
        }
        return `${width / 12}px`;
      }
      return `${width / 14}px`;

    } if (type === 'level') {
      if (width < bigScreen) {
        if (width < smallScreen) {
          if (width < mobile) {
            return `${7}rem`;
          }
          return `${8}rem`;
        }
        return `${width / 10}px`;
      }
      return `${width / 14}px`;

    } if (type === 'key') {
      if (width < bigScreen) {
        if (width < smallScreen) {
          if (width < mobile) {

            return `${5.5}rem`;

          }
          return `${6}rem`;
        }
        return `${width / 15}px`;
      }
      return `${width / 18}px`;
    }

  }
};
