/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { Image } from 'react-konva';

export class KanvaImage extends Component {
  constructor(props) {
    super(props);
    this.state = { image: null };
  }

  componentDidMount() {
    const { imageSrc } = this.props;
    const image = new window.Image();
    // eslint-disable-next-line import/no-dynamic-require
    image.src = require(`img/${imageSrc}`);
    image.onload = () => {
      this.setState({
        image,
      });
    };
  }

  render() {
    const { image } = this.state;
    const {
      width, height, x, y,
    } = this.props;

    return (
      <Image
        image={image}
        size={{ width, height }}
        position={{ x, y }}
      />
    );
  }
}

export default KanvaImage;
