import PropTypes from 'prop-types';
import React from 'react';
import { DisplayMessage } from './displayMessage';

const content = (
  <div className='mb2'>
    Come back later
  </div>
);

export const NotFound = React.memo(({ staticContext }) => {

  if (staticContext) {
    staticContext.statusCode = 404;
  }

  return (
    <DisplayMessage header='Page not found' content={content} />
  );
});

NotFound.displayName = 'NotFound';
NotFound.propTypes = {
  staticContext: PropTypes.object,
};
