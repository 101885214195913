import Joi from 'joi';

export const loginSchema = {
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .max(50)
    .required()
    .label('Email'),
  password: Joi.string()
    .required()
    .label('Password')
    .max(30)
    .min(8),
};

export const signUpSchema = {
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required().max(50).label('Email'),
  firstName: Joi.string()
    .trim()
    .required()
    .max(50)
    .label('First Name'),
  lastName: Joi.string()
    .trim()
    .required()
    .max(50)
    .label('Last Name'),
  password: Joi.string()
    .required()
    .label('Password').min(8),
};

export const forgotPasswordSchema = {
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required()
    .max(50)
    .label('Email'),
};
