export const songReducer = (state = null, action) => {
  switch (action.type) {
    case 'SONGS_ADDED':
      return action.objects;
    case 'SET_SONG_FAVORITE':
      if (!state) {
        return state;
      }

      return state.map((song) => {
        if (song.songID !== action.songID) {
          return song;
        }
        return {
          ...song,
          isFavorite: action.isFavorite,
        };
      });
    default:
      return state;
  }
};

export const featuredSongsReducer = (state = false, action) => {
  switch (action.type) {
    case 'FEATURED_SONGS_ADDED':
      return action.objects;
    default:
      return state;
  }
};
