/* eslint-disable radix */
import React from 'react';
import {
  TAB_BOARD_TO_SCREEN_RATIO,
  TAB_TOP_TO_BOARD_RATIO,
  TAB_STAFF_TO_BOARD_RATIO,
} from './constants';

export const setExactTimeout = function (callback, nextNoteDuration, resolution, canPaintNextNote) {
  // start interval time
  const start = (new Date()).getTime();

  const timeout = setInterval(() => {
    // get current time
    const offsetExecTime = new Date().getTime();

    // if the difference between current time and start time is greater than the next note duration,
    // execute the callback function - which will paint the next note
    // also clear the interval
    if ((offsetExecTime - start > nextNoteDuration) && canPaintNextNote) {
      callback();
      clearInterval(timeout);
    }
  }, resolution);

  return timeout;
};

export const getSongTime = (initialSong, repeatersSong) => {
  let time;

  const getLastNoteTime = (sng) => {
    let tm = 0;

    if (sng.length > 0) {
      for (let i = sng.length - 1; i >= sng.length - 4; i--) {
        if (sng[i][0]?.time !== 0) {
          tm = sng[i][0].time;
          break;
        }
      }
    }

    return tm;
  };

  if (repeatersSong.length > 0) {
    const lastRepeaterIdx = repeatersSong.length - 1;
    const lastRepeater = repeatersSong[lastRepeaterIdx];

    time = getLastNoteTime(lastRepeater);
    return time;
  }

  time = getLastNoteTime(initialSong);
  return time;
};

export const getTabBoardDimensions = (playerWidth) => {
  const width = playerWidth;
  const height = TAB_BOARD_TO_SCREEN_RATIO * playerWidth;

  return {
    width,
    height,
  };
};

/* Assumes only 4 strings are used
 * @returns {Object.<number, number>}
 */
export const getStringYPositions = (playerWidth) => {
  const boardHeight = playerWidth * TAB_BOARD_TO_SCREEN_RATIO;

  const tabLinesYOffset = boardHeight * TAB_TOP_TO_BOARD_RATIO;
  const tabLinesHeight = boardHeight * TAB_STAFF_TO_BOARD_RATIO;

  return {
    1: tabLinesYOffset,
    2: tabLinesYOffset + tabLinesHeight / 3,
    3: tabLinesYOffset + tabLinesHeight * (2 / 3),
    4: tabLinesYOffset + tabLinesHeight,
  };
};

export const getBrowserData = () => {
  const browser = navigator.userAgent;
  let version = 0;
  let name = 'unknown';
  const isIOS = (/iPad|iPhone|iPod/.test(browser))
  || (navigator?.userAgentData?.platform === 'MacIntel'
  && navigator?.maxTouchPoints > 1);
  const isChrome = (browser.indexOf('Chrome') !== -1);
  const isOpera = (browser.indexOf('Opera') !== -1);
  const isSafari = (browser.indexOf('Safari') !== -1);
  const isFirefox = (navigator.userAgent.indexOf('Firefox') !== -1);
  const isEdge = (browser.match(/edg/i));

  if (isIOS && isSafari) {
    version = parseInt((browser?.split('Safari/')[1]));
    name = 'Safari';
  }
  if (isChrome) {
    version = isIOS ? parseInt((browser?.split('Version/')[1].split('Safari')[0])) : parseInt((browser?.split('Chrome/')[1].split('Safari')[0]));
    name = 'Chrome';
  }
  if (isEdge) {
    version = parseInt((browser?.split('Edg/')[1]));
    name = 'Edge';
  }

  if (isFirefox) {
    version = parseInt((browser?.split('Firefox/')[1]));
    name = 'Firefox';
  }

  if (isOpera) {
    version = parseInt((browser?.split('OPR/')[1]));
    name = 'Opera';
  }

  return {
    browser, version, name, isIOS,
  };
};

const getXCoordinateRelativeToDiv = (e) => {
  const rect = e.target.getBoundingClientRect();
  const x = e.clientX - rect.left;
  const percentX = (x / rect.width) * 100;

  return percentX;
};

const getLastNoteIdxNotRest = (song, idx) => {
  let lastNoteIdx = 0;

  if (song.length > 0) {
    for (let i = idx; i >= 0; i--) {
      if (song[i][0]?.pitch) {
        lastNoteIdx = i;
        break;
      }
    }
  }
  return lastNoteIdx;
};

export const useHandleProgressBar = (updateNotesOnScreen, setBarProgress, updatePlayer, modeIndex, repeaters, notes, songTime) => {
  const [isDragging, setIsDragging] = React.useState(false);

  const handleBarOnChange = React.useCallback(async (percentX) => {
    const newCurrentTime = (songTime * percentX) / 100;
    // repeaters contain the full time length of the song
    // when there's no repeaters, the song is just the initial song
    const playingSong = repeaters.length > 0
      ? repeaters[repeaters.length - 1]
      : notes;

    // get the first note that is after the current time
    const checkNoteIdxCallback = (note) => (note[0].pitch && note[0].time >= newCurrentTime);
    // since we get the first note after the current time, we need to subtract 1 note
    // to get the last note that is before the current time
    const noteIdxAfterPickedTime = playingSong.findIndex(checkNoteIdxCallback) >= 0
      ? playingSong.findIndex(checkNoteIdxCallback) - 1
      : 0;
    // now we get the last note that is before the current time
    // but that is actually a note and not a rest
    // and thus can be painted
    const lastNoteIdxNotRest = getLastNoteIdxNotRest(playingSong, noteIdxAfterPickedTime);

    const newPlayingNote = playingSong[lastNoteIdxNotRest];
    // update screen notes
    updateNotesOnScreen(newPlayingNote[0]);
    updatePlayer(modeIndex, newPlayingNote, lastNoteIdxNotRest);

  }, [updateNotesOnScreen, updatePlayer, modeIndex, notes, repeaters, songTime]);

  const updateBarPosition = React.useCallback((e) => {
    const percentX = getXCoordinateRelativeToDiv(e);

    setBarProgress(percentX);
  }, [setBarProgress]);

  const barOnClick = React.useCallback(async (e) => {
    const percentX = getXCoordinateRelativeToDiv(e);

    updateBarPosition(e, percentX);
    handleBarOnChange(percentX);
  }, [handleBarOnChange, updateBarPosition]);

  React.useEffect(() => {
    if (isDragging) {
      window.addEventListener('mousemove', barOnClick);
    } else return;

    return () => {
      window.removeEventListener('mousemove', barOnClick);
    };
  }, [isDragging, barOnClick]);

  const startDrag = (e) => {
    e.preventDefault(); // prevent text cursor when dragging in Safari
    setIsDragging(true);
  };

  const stopDrag = () => {
    setIsDragging(false);
  };

  return {
    barOnClick,
    startDrag,
    stopDrag,
    isDragging,
  };
};
