import { string, func, bool } from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';
import { logout } from 'site/actions/authentication';
import { Button } from 'site/components/atoms/button/button';

export const HeaderLinks = ({
  className, hideLinks, useSecondaryBackground, ...props
}) => {
  const userInfo = useSelector((state) => state.userInfo);
  const location = useLocation();
  const dispatch = useDispatch();
  const { pathname } = location;

  const handleLogout = async () => {
    await dispatch(logout());
    hideLinks();
  };

  return (
    <ul className={`header__links ${className}`} {...props}>
      <li>
        <Link onClick={hideLinks} to='/songs'>Songs</Link>
      </li>
      {!userInfo
        ? pathname !== '/login'
          && (
            <Link to='/login' onClick={hideLinks}>
              <Button color={useSecondaryBackground ? 'secondary-gray' : 'gray'} size='sm' noBorder>
                SIGN IN
              </Button>
            </Link>
          )
        : (
          <Button color={useSecondaryBackground ? 'secondary-gray' : 'gray'} size='sm' width='sm' noBorder onClick={handleLogout}>
            <Icon name='user' />
            LOG OUT
          </Button>
        )}
    </ul>
  );
};

HeaderLinks.propTypes = {
  className: string,
  hideLinks: func,
  useSecondaryBackground: bool.isRequired,
};
