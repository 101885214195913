import { handleError, showSuccess, showError } from './utils';

export const getSongsData = () => handleError(async (dispatch, getState, { api }) => {
  const objects = await api.getAllSongs();
  if (objects) {
    dispatch({ type: 'SONGS_ADDED', objects });
    return true;
  }
}, 'Error while downloading the songs list');

export const getFeaturedSongsData = () => handleError(async (dispatch, getState, { api }) => {
  const objects = await api.getFeaturedSongs();
  if (objects) {
    dispatch({ type: 'FEATURED_SONGS_ADDED', objects });
    return true;
  }
}, 'Error while downloading the songs list');

export const getSong = (songId, songName) => handleError(async (dispatch, getState, { api }) => {
  const song = await api.getSong(songId, songName);

  return song;
}, 'Error while downloading the song');

export const toggleSongFavorite = (songID, isFavorite) => handleError(async (dispatch, getState, { api }) => {
  const method = isFavorite ? 'DELETE' : 'POST';
  const { status } = await api.addSongToFavorite(songID, method);

  if (status === 200) {
    showSuccess({ text: `Song ${isFavorite ? 'removed from' : 'added to'} favorites` });

    dispatch({
      type: 'SET_SONG_FAVORITE',
      songID,
      isFavorite: !isFavorite,
    });
  } else {
    showError({ text: 'Error updating favorites status' });
  }
}, 'Error updating favorites status');
