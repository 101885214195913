import './app.less';

import React from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import {
  Route, Routes, Navigate, useNavigate, useLocation,
} from 'react-router-dom';
import { hot } from 'react-hot-loader/root';

import { Layout } from 'site/components/layout';
import { Home } from 'site/pages/home/home';
import favicon from 'img/favicon.png';
import { SongPage } from 'site/pages/songs/song';
import {
  SignUpForm, LoginForm, ForgotPasswordForm, ResetPasswordForm,
} from './authentication';
import { SongsPage } from '../pages/songs/songsPage';
import { DimmerLoader } from './common/loaders';
import { NotFound, ErrorBoundary, ErrorPage } from './common';

export const App = hot(() => {
  const userInfo = useSelector((state) => state.userInfo);
  const errorMessage = useSelector((state) => state.errorMessage);
  const initializing = useSelector((state) => state.siteInfo.initializing);
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div id={window.__ENVIRONMENT__.APP_NAME} className='min-h-100'>
      <Helmet defaultTitle={window.__ENVIRONMENT__.APP_NAME}>
        <html lang='en' />
        <meta name='description' content={window.__ENVIRONMENT__.APP_NAME} />
        <meta name='viewport' content='width=device-width, initial-scale=1, shrink-to-fit=no' />
        <link rel='preconnect' href='https://fonts.googleapis.com' />
        <link rel='preconnect' href='https://fonts.gstatic.com' crossOrigin />
        <link
          href='https://fonts.googleapis.com/icon?family=Material+Icons'
          rel='stylesheet'
        />
        <link href='https://fonts.googleapis.com/css2?family=Lora:wght@300;400;500;600;700&family=Merriweather:wght@300;400;500;600;700&family=Inter:wght@300;400;500;600;700&family=Nunito:wght@300;400;500;600;700&family=Roboto&display=swap' rel='stylesheet' />
        <link rel='icon' href={favicon} />
      </Helmet>
      {errorMessage
        ? <ErrorPage error={errorMessage} />
        : (
          <ErrorBoundary>
            <Layout>
              <Routes>
                {initializing ? (
                  <>
                    <Route
                      path='*'
                      element={(
                        <DimmerLoader message='Accessing, wait a moment please...' />
                      )}
                    />
                  </>
                )
                  : (
                    <>
                      <Route exact path='/' element={<Home navigate={navigate} location={location} />} />
                      <Route path='/404' element={<NotFound />} />
                      <Route path='/song/:songId/:songName' element={<SongPage />} />
                      <Route path='/song-no-layout/:songId' element={<SongPage />} />
                      <Route exact path='/songs' element={<SongsPage navigate={navigate} location={location} />} />

                      <Route path='/login' element={userInfo ? <Navigate to='/' from='/login' /> : <LoginForm navigate={navigate} />} />
                      <Route path='/signup' element={userInfo ? <Navigate to='/' from='/signup' /> : <SignUpForm navigate={navigate} />} />
                      <Route path='/forgot-password' element={<ForgotPasswordForm navigate={navigate} location={location} />} />
                      <Route path='/new-password' element={<ResetPasswordForm navigate={navigate} location={location} />} />

                      <Route path='*' element={<Navigate to='/404' />} />
                    </>
                  )}
              </Routes>
            </Layout>
          </ErrorBoundary>
        )}
    </div>
  );
});
