import { combineReducers } from 'redux';

const initializingReducer = (state = false, action) => {
  switch (action.type) {
    case 'INITIALIZING':
      return action.running;
    default:
      return state;
  }
};

const isWorking = (state = 0, action) => {
  switch (action.type) {
    case 'BEGIN_OPERATION': return state + 1;
    case 'END_OPERATION': return state - 1;
    default: return state;
  }
};

const secondaryBackground = (state = false, action) => {
  switch (action.type) {
    case 'CHANGE_BACKGROUND': return action.value;
    default: return state;
  }
};

export const siteInfoInfoReducer = combineReducers({
  initializing: initializingReducer,
  isWorking,
  secondaryBackground,
});
