import { Link } from 'react-router-dom';
import { Button } from 'site/components/atoms/button/button';
import { CustomIcon } from 'site/components/atoms/icons';
import './unallowed.less';

export const UnAllowed = () => (
  <div className='form-unallowed flex flex-column min-vh-100 w-350 pa3 justify-center'>
    <div className='form-unallowed--block center flex-column items-center justify-center self-center'>
      <div className='unallowed__form'>
        <Link to='/songs'>
          <CustomIcon name='close' className='close-modal' />
        </Link>
        <div className='elements'>
          <div className='unallowed__description'>Please sign in to access this content</div>
          <Link to='/login'>
            <Button outline color='primary' className='unallowed__buttonWhite' size='sm'>
              SIGN IN
            </Button>
          </Link>
          <div className='unallowed__description'>Creating an account is free!</div>
          <Link to='/signUp'>
            <Button color='primary' className='unallowed__buttonPrimary' size='sm'>
              SIGN UP
            </Button>
          </Link>
        </div>
      </div>
    </div>
  </div>
);
