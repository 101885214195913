import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {
  Form, Input,
} from 'semantic-ui-react';
import { Button } from 'site/components/atoms/button/button';
import PropTypes from 'prop-types';

import { Popup } from 'controls';
import { login } from 'site/actions/authentication';

import { useFieldset, useDispatchCallback } from 'controls/hooks';
import { useAsyncSubmit } from 'controls/hooks/misc';
import { loginSchema } from 'controls/schemas/auth';
import './login.less';

const loginForm = { email: '', password: '' };

export const LoginForm = memo(({ navigate }) => {

  const switchToDetailsMode = () => navigate('/');
  const loginAndGoToMain = useAsyncSubmit(useDispatchCallback(login), switchToDetailsMode);
  const { fields: { email, password }, submit, loading } = useFieldset({ schema: loginSchema, source: loginForm, onSubmit: loginAndGoToMain });

  return (
    <div className='form flex flex-column min-vh-100 w-100 pa3 justify-center'>
      <Helmet title={`Sign in | ${window.__ENVIRONMENT__.APP_NAME}`} />
      <div className='form--block center flex-column items-center justify-center self-center'>
        <div className='form__title'>Sign In</div>
        <Form onSubmit={submit} className='login__form'>
          <Form.Field error={email.errored}>
            <label>email</label>
            <Popup message={email.message} enabled={email.errored} open={!!(email.errored)}>
              <Input fluid autoComplete='email' value={email.value} onChange={email.onChange} />
            </Popup>
          </Form.Field>
          <Form.Field error={password.errored}>
            <label>password</label>
            <Popup message={password.message} enabled={password.errored} open={!!(password.errored)}>
              <Input fluid type='password' autoComplete='current-password' value={password.value} onChange={password.onChange} />
            </Popup>
          </Form.Field>
          <Button color='primary' className='login__button' disabled={loading} loading={loading}>
            SIGN IN
          </Button>
          <Link to='/forgot-password' className='login__link'>Forgot your password?</Link>
        </Form>
      </div>
    </div>
  );
});

LoginForm.propTypes = {
  navigate: PropTypes.func.isRequired,
};
