import Cookies from 'js-cookie';

export class Cookie {
  static initializeServer(serverCookies) {
    this.serverCookies = serverCookies;
  }

  static get(name) {
    if (this.serverCookies) {
      return this.serverCookies[name];
    }
    return Cookies.get(name);
  }

  static set(...args) {
    return Cookies.set(...args);
  }

  static remove(...args) {
    return Cookies.remove(...args);
  }

}
