import { Midi } from '@tonejs/midi';

export const insert = (arr, index, newItem) => [
  ...arr.slice(0, index),
  newItem,
  ...arr.slice(index),
];

export const throttle = (func, delay) => {
  let inProgress = false;
  return (...args) => {
    if (inProgress) {
      return;
    }
    inProgress = true;
    setTimeout(() => {
      func(...args);
      inProgress = false;
    }, delay);
  };
};

export const fileToMidi = (file) => new Promise((resolve, reject) => {
  const reader = new FileReader();

  reader.onload = () => {
    const midi = new Midi(reader.result);
    resolve({ ppq: midi.header?.ppq, timeSignature: midi.header?.timeSignatures[0]?.timeSignature[0] || 4, notes: midi.tracks[0].notes });
  };

  reader.onerror = reject;

  reader.readAsArrayBuffer(file);
});

export const isEmpty = (obj) => [Object, Array].includes((obj || {}).constructor) && !Object.entries((obj || {})).length;
