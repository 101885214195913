/* eslint-disable no-loop-func */
import _ from 'lodash';

let bpm;

export const setRepeaters = (xmlSong, beatsPM = 120) => {
  bpm = beatsPM;

  /* how many times setRepeater will be called
  in pushExpandedSong method */
  let repeatersCount = 0;

  for (let i = 0; i < xmlSong.length; i++) {
    if (xmlSong[i][0]?.repeater && xmlSong[i][0]?.direction === 'backward') {
      repeatersCount += 1;
    }
  }

  const songsWithExpandedRepeaters = [];

  const pushExpandedSong = (i, song) => {
    const expandedSong = setRepeater(
      song,
      song[i][0],
      i,
    );
    songsWithExpandedRepeaters.push(expandedSong);
    return expandedSong;
  };

  // use last song as reference
  let lastUpdatedSong = xmlSong;
  const ignoreRepeater = [];

  while (repeatersCount > 0) {
    for (let i = 0; i < lastUpdatedSong.length; i++) {
      if (lastUpdatedSong[i][0]?.repeater && lastUpdatedSong[i][0]?.direction === 'backward' && lastUpdatedSong[i][0]?.repeaterCount >= 1 && !ignoreRepeater.includes(i)) {

        // push song to array of expanded songs
        lastUpdatedSong = pushExpandedSong(i, lastUpdatedSong);

        /* ignore this repeater next loop
        by removing it from the array
        that is checked in the condition above */
        ignoreRepeater.push(i);
        repeatersCount -= 1;
        break;
      }
    }
  }

  return songsWithExpandedRepeaters;
};

const getNoteMsDuration = (note) => {
  let msPerBeat = 60 / bpm;
  if (note.isTuplet) {
    msPerBeat *= (2 / 3);
  }

  // for a 4/4 song
  const noteLength = {
    'complete': msPerBeat * 4,
    'whole': msPerBeat * 4,
    'half': msPerBeat * 2,
    'quarter': msPerBeat * 1,
    'eighth': msPerBeat * 0.5,
    '16th': msPerBeat * 0.25,
    '32nd': msPerBeat * 0.125,
  };

  let msDuration;
  switch (note.duration) {
    case 'complete':
      msDuration = !note.dot
        ? noteLength.complete
        : noteLength.complete * 1.5;
      break;
    case 'whole':
      msDuration = !note.dot
        ? noteLength.whole
        : noteLength.whole * 1.5;
      break;
    case 'half':
      msDuration = !note.dot
        ? noteLength.half
        : noteLength.half * 1.5;
      break;
    case 'quarter':
      msDuration = !note.dot
        ? noteLength.quarter
        : noteLength.quarter * 1.5;
      break;
    case 'eighth':
      msDuration = !note.dot
        ? noteLength.eighth
        : noteLength.eighth * 1.5;
      break;
    case '16th':
      msDuration = !note.dot
        ? noteLength['16th']
        : noteLength['16th'] * 1.5;
      break;
    case '32nd':
      msDuration = !note.dot
        ? noteLength['32nd']
        : noteLength.complete * 1.5;
      break;
    default:
      break;
  }
  return msDuration;
};

/* removes volta notes depending
if its a repeated section, non repeated
will remove volta === 2 and repeated
will remove volta === 1 */
const removeVoltaNotes = (section) => {
  const sectionWithNoVolta = [];
  let voltaSectionsBuffer = [];

  let voltaVal = null;
  let voltaBufferIsSaved = false;
  let stop = false;

  for (let i = 0; i < section.length; i++) {

    /* save the first volta value
    to compare vs the next ones
    done just once */
    if (!voltaBufferIsSaved && section[i][0]?.volta) {
      voltaVal = section[i][0].volta;
      voltaSectionsBuffer.push(section[i][0].measure);
      voltaBufferIsSaved = true;
    }

    /* add more measures to the buffer in case of
    brackets with more than one measure */
    if (section[i][0]?.volta === voltaVal && !stop) {
      voltaSectionsBuffer.push(section[i][0].measure);
      voltaSectionsBuffer = _.sortedUniq(voltaSectionsBuffer); // remove duplicates
    }

    /* stop pushing volta measures into the buffer once volta bracket
    has different number, so next voltas will be pushed
    be effectively pushed */
    if (section[i][0]?.volta !== voltaVal && section[i][0]?.volta) {
      stop = true;
    }

    /* push all other items that have no volta */
    if (!section[i][0]?.volta) {
      sectionWithNoVolta.push(section[i].map((el) => ({
        ...el,
      })));
    }

    /* don't let out volta notes from next section */
    if (section[i][0].volta && !voltaSectionsBuffer.includes(section[i][0].measure)) {
      sectionWithNoVolta.push(section[i].map((el) => ({
        ...el,
      })));
    }
  }

  return sectionWithNoVolta;
};

/* add to the last section which
have been removed from volta = 1
and add them the volta = 2 from
the next section only */
const updateVoltaInSection = (lastSectionWithNoVolta, sectionAfterRepeater) => {

  let voltaTwo = false;
  for (let i = 0; i < sectionAfterRepeater.length; i++) {
    const note = sectionAfterRepeater[i];

    if (sectionAfterRepeater[i][0]?.volta) {
      lastSectionWithNoVolta.push(note);
      voltaTwo = true;
    }
    /* measure === end points end of the song */
    if (sectionAfterRepeater[i][0].volta !== '2' && voltaTwo) {

      return lastSectionWithNoVolta;
    }
  }
  return lastSectionWithNoVolta;
};

const updateNoteData = (mutatedSong) => {
  let lastTimer = 0;
  const adjustedSong = [];
  let lastNoteMsDuration = 0;
  let firstNote = true;

  for (let i = 0; i < mutatedSong.length; i++) {
    if (mutatedSong[i][0]?.pitch || mutatedSong[i][0]?.isRest) {
      if (firstNote) {
        adjustedSong[i] = mutatedSong[i].map((el) => ({
          ...el,
          time: 0,
        }));
        lastNoteMsDuration = getNoteMsDuration(mutatedSong[i][0]);
        firstNote = false;
      } else {
        adjustedSong[i] = mutatedSong[i].map((el) => ({
          ...el,
          time: lastTimer + lastNoteMsDuration,
        }));
        // update time for next note
        lastNoteMsDuration = getNoteMsDuration(mutatedSong[i][0]);
        lastTimer = adjustedSong[i][0].time;
      }
    } else {
      adjustedSong[i] = mutatedSong[i].map((el) => ({
        ...el,
        time: lastTimer,
      }));
    }
  }
  return adjustedSong;
};

export const setRepeater = (
  song,
  repeater /* backward repeater */,
  idx, /* repeater idx */
) => {

  let mutatingSong;
  const repeaterEndCount = repeater.repeaterCount;
  let finalSong;

  for (let i = 0; i < idx; i++) {
    // set the start of the expanded section
    if (song[i][0]?.repeaterCount === repeaterEndCount - 1) {
      const sectionStart = i + 1;

      /* This section remains untouched
      since its sliced when the first
      bracket ends. Contains only first
      bracket volta notes */
      const firstSection = song.slice(0, idx + 1);

      /* This is the same section than before
      but contains second volta notes */
      const section = removeVoltaNotes(song.slice(sectionStart, idx), true);
      const secondSection = (song.slice(idx + 1, song.length));
      const sectionVoltaUpdated = updateVoltaInSection(section, secondSection);

      /* here is the rest of the song
      after having repeated the last section */
      const secondSectionVoltaUpdated = removeVoltaNotes(secondSection, false);

      mutatingSong = [
        ...firstSection,
        ...sectionVoltaUpdated,
        ...secondSectionVoltaUpdated,
      ];
      finalSong = updateNoteData(mutatingSong);
      break;
    }
  }
  return finalSong;
};
