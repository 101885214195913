import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import {
  useUrlParams, useUrlParamsHandler, useWindowDimensions, useQueryStyle,
} from 'controls/hooks';
import {
  VirtualTable, Column, playLinkRenderer, levelRenderer,
} from 'controls/table';
import { Input, Dropdown } from 'semantic-ui-react';
import { sortByProperty } from 'controls/utils';
import { getKeys } from 'site/actions/keys';
import {
  defaultParams, levelsOptions,
} from './songListUtils';
import '../songs.less';

export const SongsList = ({
  navigate, location, songs, ...props
}) => {
  const dispatch = useDispatch();
  const [filteredSongs, setFilteredSongs] = useState([]);
  const keys = useSelector((state) => state.keys?.length && state.keys.map(({ name }) => ({ text: name, value: name })));

  useEffect(() => {
    if (!keys) {
      dispatch(getKeys());
    }
  }, [dispatch, keys]);

  const keysOptions = keys?.length ? [{ text: 'All', value: '' }, ...keys] : [];
  const styles = songs?.map((song) => song?.style)?.reduce((previousValue, currentValue) => {
    if (previousValue.indexOf(currentValue) === -1) {
      previousValue.push(currentValue);
    }
    return previousValue;
  }, []);
  const mappedStyles = styles?.map((song) => ({ text: song, value: song }));
  const stylesOptions = mappedStyles?.length ? [{ text: 'All', value: '' }, ...mappedStyles] : [];
  const userInfo = useSelector((state) => state.userInfo);
  const urlParams = useUrlParams(location.search, defaultParams);
  const onSortChange = useUrlParamsHandler({ navigate, location });
  const onSearchChange = useUrlParamsHandler({ navigate, location, key: 'search' });
  const onPageChange = useUrlParamsHandler({ navigate, location, key: 'page' });
  const onStyleChange = useUrlParamsHandler({ navigate, location, key: 'style' });
  const onLevelChange = useUrlParamsHandler({ navigate, location, key: 'level' });
  const onKeyChange = useUrlParamsHandler({ navigate, location, key: 'key' });
  const currentLocation = location.pathname.split('/')[1];
  const isHome = currentLocation === '';
  const { windowDimensions: { width } } = useWindowDimensions();
  const widthStyle = useQueryStyle(width, 'style');
  const widthLevel = useQueryStyle(width, 'level');
  const widthKey = useQueryStyle(width, 'key');
  const rowStyleFormat = (index) => {
    if (index < 0) return;
    if (index % 2 === 0) {
      return {
        backgroundColor: '#F6F6F6',
      };
    }
    return {
      backgroundColor: 'white',
    };
  };
  useEffect(() => {
    if (songs && (urlParams.level || urlParams.key)) {
      if (
        (urlParams.level && !urlParams.key)
        || (urlParams.key && !urlParams.level)) {
        setFilteredSongs(songs.filter(
          (song) => song.level === parseInt(urlParams.level, 10)
          || song.key === urlParams.key,
        ));
      } else {
        setFilteredSongs(songs.filter(
          (song) => song.level === parseInt(urlParams.level, 10)
          && song.key === urlParams.key,
        ));
      }
    } else {
      setFilteredSongs(songs);
    }

  }, [urlParams.level, urlParams.key, songs]);

  return (
    <section className='songs-list'>
      <div className='center'>
        {!isHome && (
          <Input
            icon='search'
            className='songs__input'
            value={urlParams.search}
            onChange={onSearchChange}
          />
        )}
        <div className='songs__filters'>
          <div className='songs__name'>
            name
          </div>
          {!isHome && (
          <div className='songs__filters__dropdown'>
            <Dropdown
              className='songs__control songs__dropdown__style'
              options={stylesOptions}
              onChange={onStyleChange}
              value={urlParams.style}
              placeholder='Style'
              style={{ width: widthStyle }}
            />
            <Dropdown
              className='songs__control songs__dropdown__level'
              options={levelsOptions}
              onChange={onLevelChange}
              value={urlParams.level}
              placeholder='Level'
              style={{ width: widthLevel }}
            />
            <Dropdown
              className='songs__control songs__dropdown__key'
              options={keysOptions}
              onChange={onKeyChange}
              value={urlParams.key}
              scrolling
              placeholder='Key'
              style={{ width: widthKey }}
            />
          </div>
          )}

        </div>
        <VirtualTable
          source={filteredSongs?.sort(sortByProperty('title'))}
          minWidth={650}
          sortSearchParams={urlParams}
          onSortChange={onSortChange}
          onPageChange={onPageChange}
          rowHeight={60}
          rowStyle={({ index }) => rowStyleFormat(index)}
          {...props}
        >
          <Column
            dataKey='songID'
            label=''
            className='black'
            flexGrow={1}
            cellRenderer={(data) => playLinkRenderer(data, userInfo)}
            width={width / 12}
          />
          <Column
            dataKey='title'
            label=''
            flexGrow={1}
            className='song-title'
            width={width / 3}
            maxWidth={width < 1115 ? width < 450 ? 410 : 160 : (width / 2)}
          />
          <Column
            dataKey='style'
            label=''
            flexGrow={1}
            className='song-style'
            width={width < 1115 ? width < 426 ? 55 : 160 : (width / 12)}
          />
          <Column
            dataKey='level'
            label=''
            flexGrow={1}
            cellRenderer={levelRenderer}
            searchKey='level'
            width={width < 1115 ? width < 426 ? 40 : 150 : (width / 10)}
          />
          <Column
            dataKey='key'
            className='song-key'
            label=''
            flexGrow={1}
            searchKey='key'
            width={width < 1115 ? width < 426 ? 30 : 150 : (width / 15)}
          />
        </VirtualTable>
      </div>
    </section>
  );
};

export default SongsList;

SongsList.propTypes = {
  navigate: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  songs: PropTypes.arrayOf(PropTypes.any),
};
