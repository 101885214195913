import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Container } from 'site/components/layout/container';
import { SongsList } from 'site/components/songs';
import { getSongsData } from 'site/actions/songs';

export const SongsPage = ({ navigate, location }) => {
  const dispatch = useDispatch();
  const songs = useSelector((state) => state.songs);

  useEffect(() => {
    if (!songs) {
      dispatch(getSongsData());
    }
  });

  return (
    <Container>
      <Helmet title={`Songs listing | ${window.__ENVIRONMENT__.APP_NAME}`} />
      <div className='songs__container'>
        <div className='center'>
          <div className='songs__header mb4'>
            <div className='songs__title'>Songs</div>
          </div>
        </div>
        <SongsList songs={songs} navigate={navigate} location={location} />
      </div>
    </Container>
  );

};

SongsPage.propTypes = {
  navigate: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};
