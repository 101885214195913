import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { SongsList } from 'site/components/songs';
import { Spinner } from 'site/components/atoms/spinner/spinner';
import { Button } from 'site/components/atoms/button/button';
import { sortByProperty } from 'controls/utils';
import { getFeaturedSongsData } from 'site/actions/songs';

export const HomeSongs = ({ navigate, location }) => {
  const dispatch = useDispatch();
  const featuredSongs = useSelector((state) => state.featuredSongs);
  const initializing = useSelector((state) => state.siteInfo.initializing);
  const ellipsisSize = featuredSongs.length < 26 ? 235 : featuredSongs.length > 34 ? 435 : 535;
  const userInfo = useSelector((state) => state.userInfo);

  useEffect(() => {
    if (!featuredSongs) {
      dispatch(getFeaturedSongsData());
    }
  }, [dispatch, featuredSongs]);

  return (
    <div className='home__songs'>
      {!userInfo
        && (
          <div className='home__songs__sign-up'>
            <Link to='/signUp'>
              <Button color='primary' className='home__songs__sign-up--btn'>
                TRY FOR FREE
              </Button>
            </Link>
            <p className='home__songs__sign-up--text'>As we build our content, our only ask is that you give us feedback on what mandolin tabs you want to see, so we can make the site a better learning experience!</p>
          </div>
        )}
      {!featuredSongs && !initializing && <Spinner inline='centered' />}
      {featuredSongs.length > 0
        && (
          <div>
            <div className='center'>
              <div className='songs__header mb4'>
                <h3 className='home__songs--title'>Featured songs</h3>
              </div>
            </div>
            <SongsList songs={featuredSongs?.sort(sortByProperty('title'))} navigate={navigate} location={location} showTotals={false} />
            <div className='home__songs__ellipsis' style={{ height: ellipsisSize, width: ellipsisSize, top: `${featuredSongs.length + 55}rem` }} />
            <div className='home__songs__button'>
              <Link to='/songs'>
                <Button color='primary' outline>
                  SEE ALL
                </Button>
              </Link>
            </div>
          </div>
        )}
    </div>
  );
};

HomeSongs.propTypes = {
  navigate: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};
