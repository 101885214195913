import _ from 'lodash';
import swal from 'sweetalert';

export const ERROR_MESSAGE = 'Oops, something happened';
export const showSuccess = (args) => swal({ ...args, icon: 'success', button: { text: 'Okay', className: 'grow bg-orange-important br-pill-important' } });
export const showError = (args) => swal({ ...args, icon: 'error', button: { text: 'Okay', className: 'grow bg-orange-important br-pill-important' } });

export const handleError = (fn, errorMessage = '', { rethrow = false, silent = false } = {}) => async (dispatch, ...rest) => {
  try {
    dispatch({ type: 'BEGIN_OPERATION' });
    return await fn(dispatch, ...rest);
  } catch (err) {
    errorMessage = _.isFunction(errorMessage) ? errorMessage(dispatch, ...rest) : errorMessage;
    let exceptionMessage = _.isString(err)
      ? err : _.isObject(err)
        ? err.message || err.messages || err.responseText || JSON.stringify(err)
        : 'An unknown error has ocurred';

    if (!_.isString(exceptionMessage)) {
      exceptionMessage = JSON.stringify(exceptionMessage);
    }

    if (process.env.NODE_ENV !== 'production') {
      console.error(err);
    }

    if (!silent) {
      showError({ title: errorMessage || undefined, text: exceptionMessage });
    }

    if (rethrow) {
      throw new Error(`${errorMessage}\n${exceptionMessage}`);
    }
  } finally {
    dispatch({ type: 'END_OPERATION' });
  }
};
