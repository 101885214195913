import React from 'react';
import PropTypes from 'prop-types';

import {
  TAB_BOARD_TO_SCREEN_RATIO,
  TAB_TOP_TO_BOARD_RATIO,
  TAB_STAFF_TO_BOARD_RATIO,
} from '../constants';

const TabBoardDimensionsContext = React.createContext();

function getDimensionsRelativeToParent(parent) {
  const parentWidth = parent.clientWidth;

  const width = parentWidth;
  const height = TAB_BOARD_TO_SCREEN_RATIO * parentWidth;

  const staffHeight = height * TAB_STAFF_TO_BOARD_RATIO;
  const topHeight = height * TAB_TOP_TO_BOARD_RATIO;

  return {
    width,
    height,
    staffHeight,
    topHeight,
  };
}

export const TabBoardDimensionsContextProvider = ({ children, parentRef }) => {
  const [value, setValue] = React.useState(
    getDimensionsRelativeToParent(parentRef.current),
  );

  React.useEffect(() => {
    const resizeHandler = () => {
      setValue(getDimensionsRelativeToParent(parentRef.current));
    };

    window.addEventListener('resize', resizeHandler);
    window.addEventListener('orientationchange', resizeHandler);
    window.addEventListener('webkitfullscreenchange', resizeHandler);

    return () => {
      window.removeEventListener('resize', resizeHandler);
      window.removeEventListener('orientationchange', resizeHandler);
      window.addEventListener('webkitfullscreenchange', resizeHandler());
    };
  }, [parentRef]);

  return (
    <TabBoardDimensionsContext.Provider value={value}>
      {children}
    </TabBoardDimensionsContext.Provider>
  );
};

TabBoardDimensionsContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  parentRef: PropTypes.shape({
    current: PropTypes.objectOf(PropTypes.any),
  }),
};

export default TabBoardDimensionsContext;
