import React from 'react';
import PropTypes from 'prop-types';
import Note from './Note';

function NoteMapper({
  notes,
  layoutLine,
}) {
  return (
    <>
      {notes.map((note) => (
        <Note
          notes={note}
          key={`mapped-note-${note[0].id}${note[0].time}`}
          layoutLine={layoutLine}
        />
      ))}
    </>
  );
}

NoteMapper.propTypes = {
  notes: PropTypes.arrayOf(PropTypes.array),
  layoutLine: PropTypes.number,
};

NoteMapper.defaultProps = {
  notes: [],
  layoutLine: 0,
};

export default React.memo(NoteMapper);
