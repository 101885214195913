import PropTypes from 'prop-types';
import React from 'react';
import { TextArea, Form } from 'semantic-ui-react';

import { DisplayMessage } from './displayMessage';

export const ErrorPage = React.memo(({ error }) => {

  const content = (
    <>
      We are sorry,
      <br />
      <br />
      Please send us this error message to
      {' '}
      <a href='mailto:admin@altomobile.io'>admin@altomobile.io</a>
      :
      <br />
      <br />
      <Form>
        <TextArea
          className='w-100 f4'
          value={error}
          rows={10}
          readOnly
        />
      </Form>
    </>
  );

  return (
    <DisplayMessage
      helmet='Error'
      header='Something went wrong'
      content={content}
    />
  );

});

ErrorPage.displayName = 'ErrorPage';
ErrorPage.propTypes = {
  error: PropTypes.string.isRequired,
};
