import React, { memo } from 'react';
import {
  Form, Input,
} from 'semantic-ui-react';
import { Button } from 'site/components/atoms/button/button';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import { Popup } from 'controls';
import { signUp } from 'site/actions/authentication';
import { useFieldset, useDispatchCallback } from 'controls/hooks';
import { useAsyncSubmit } from 'controls/hooks/misc';
import './signup.less';
import { signUpSchema } from 'controls/schemas/auth';

const signUpForm = {
  firstName: '', lastName: '', email: '', password: '', roleType: 'User',
};

export const SignUpForm = memo(({ navigate }) => {

  const switchToDetailsMode = () => navigate('/');
  const loginAndGoToMain = useAsyncSubmit(useDispatchCallback(signUp), switchToDetailsMode);
  const {
    fields: {
      firstName, lastName, email, password,
    }, submit, loading,
  } = useFieldset({ schema: signUpSchema, source: signUpForm, onSubmit: loginAndGoToMain });

  return (
    <div className='form flex flex-column min-vh-100 w-100 pa3 justify-center'>
      <Helmet title={`Sign up | ${window.__ENVIRONMENT__.APP_NAME}`} />
      <div className='form--block center flex-column items-center justify-center self-center'>
        <div className='form__title'>Sign up</div>
        <Form onSubmit={submit} className='signup__form'>
          <Form.Field error={firstName.errored} required>
            <label>first name</label>
            <Popup message={firstName.message} enabled={firstName.errored} open={!!(firstName.errored)}>
              <Input fluid value={firstName.value || ''} onChange={firstName.onChange} autoComplete='off' />
            </Popup>
          </Form.Field>
          <Form.Field error={lastName.errored} required>
            <label>last name</label>
            <Popup message={lastName.message} enabled={lastName.errored} open={!!(lastName.errored)}>
              <Input fluid value={lastName.value || ''} onChange={lastName.onChange} autoComplete='off' />
            </Popup>
          </Form.Field>
          <Form.Field error={email.errored} required>
            <label>email</label>
            <Popup message={email.message} enabled={email.errored} open={!!(email.errored)}>
              <Input fluid autoComplete='email' value={email.value} onChange={email.onChange} />
            </Popup>
          </Form.Field>
          <Form.Field error={password.errored} required>
            <label>password</label>
            <Popup message={password.message} enabled={password.errored} open={!!(password.errored)}>
              <Input fluid type='password' autoComplete='current-password' value={password.value} onChange={password.onChange} />
            </Popup>
          </Form.Field>
          <Button color='primary' className='login__button' disabled={loading} loading={loading}>
            SIGN UP
          </Button>
        </Form>
      </div>
    </div>
  );
});

SignUpForm.propTypes = {
  navigate: PropTypes.func.isRequired,
};
