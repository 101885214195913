import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Container } from 'site/components/layout/container';
import { Button } from 'site/components/atoms/button/button';
import { CustomIcon } from 'site/components/atoms/icons';
import './home.less';
import { HomeSongs } from 'site/components/home/homeSongs';

export const Home = ({ navigate, location }) => {
  const userInfo = useSelector((state) => state.userInfo);

  return (
    <>
      <Helmet title={`Home | ${window.__ENVIRONMENT__.APP_NAME}`} />
      <Container>
        <div className='home'>
          <div className='home__hero section group'>
            <div className='home__hero__text'>
              <div>
                <h1 className='home__hero--title'>Improve Your Mandolin Playing</h1>
                <p className='home__hero--subtitle'>
                  An easier way to practice mandolin. Beginners and experienced players alike can quickly learn new songs by following along with accurate moving tablature at half or normal speed
                </p>
              </div>
            </div>
          </div>
          <HomeSongs navigate={navigate} location={location} />
          <div className='home__video'>
            <div className='home__video--item'>
              <h1 className='home__video--title'>Sign Up Free.</h1>
              <p className='home__video--text'>Sign up for free access to all content (limited time only). Learn and improve using HD multi-angle videos, accurate tablature + score, moving tab in real time, isolated mandolin tracks, backing tracks, half-speed versions and more.</p>
              <Link to={userInfo ? '/songs' : 'signUp'}>
                <Button color='primary' outline className='home__video--btn'>
                  START NOW!
                </Button>
              </Link>
            </div>
            <figure className='home__video--item'>
              <iframe className='home__video--iframe' width='100%' height='100%' src='https://www.youtube.com/embed/1xFcIEJ5Heo' title='YouTube video player' frameBorder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowFullScreen />
            </figure>
          </div>
        </div>
      </Container>
      <div className='home__info'>
        <div className='home__info--container'>
          <h1 className='home__info--title'>What you get</h1>
          <div className='home__info__item'>
            <CustomIcon name='level-gray' className='home__info__item--img' />
            Accurate, downloadable PDFs (mandolin tabs and music) for a wide range of songs
          </div>
          <div className='home__info__item'>
            <CustomIcon name='level-gray' className='home__info__item--img' />
            Backing tracks for multiple instruments
          </div>
          <div className='home__info__item'>
            <CustomIcon name='level-gray' className='home__info__item--img' />
            HD quality videos with half-speed and full-speed option
          </div>
          <div className='home__info__item'>
            <CustomIcon name='level-gray' className='home__info__item--img' />
            Unique moving tab in time with each song for easy learning
          </div>
        </div>
      </div>
    </>
  );
};

Home.propTypes = {
  navigate: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};
