import React from 'react';

function getBrowserFullScreenElementProp() {
  if (typeof document.fullscreenElement !== 'undefined') {
    return 'fullscreenElement';
  } if (typeof document.mozFullScreenElement !== 'undefined') {
    return 'mozFullScreenElement';
  } if (typeof document.msFullscreenElement !== 'undefined') {
    return 'msFullscreenElement';
  } if (typeof document.webkitFullscreenElement !== 'undefined') {
    return 'webkitFullscreenElement';
  }

  return null;
}

const fullScreenElement = getBrowserFullScreenElementProp();
const isFullScreenSupported = !!fullScreenElement;

export function useFullScreenStatus(elRef) {
  const [isFullscreen, setIsFullscreen] = React.useState(false);

  const openFullScreen = () => {
    if (!elRef.current) return;

    const requestFullScreen = (
      elRef.current.requestFullscreen
      || elRef.current.webkitRequestFullscreen
      || elRef.current.mozRequestFullscreen
    ).bind(elRef.current);

    try {
      requestFullScreen();
      setIsFullscreen(true);
    } catch {
      setIsFullscreen(false);
    }
  };

  React.useLayoutEffect(() => {
    const handleFullScreenChange = () => setIsFullscreen(document[getBrowserFullScreenElementProp()] != null);
    document.addEventListener('fullscreenchange', handleFullScreenChange);
    document.addEventListener('webkitfullscreenchange', handleFullScreenChange);

    const cleanUp = () => {
      document.removeEventListener('fullscreenchange', handleFullScreenChange);
      document.removeEventListener('webkitfullscreenchange', handleFullScreenChange);
    };

    return cleanUp;
  });

  return [
    isFullScreenSupported,
    isFullscreen,
    openFullScreen,
    setIsFullscreen,
  ];
}
