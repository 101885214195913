import { Image } from 'semantic-ui-react';
import './noVideoScreen.less';
import PropTypes from 'prop-types';

export const NoVideoScreen = ({ customMessage = 'Video not found' }) => (
  <div className='video-player__no-screen'>
    <div className='video-player__placeholder-container'>
      <Image src={require('img/verticalLogo.png')} alt='logo' size='small' style={{ display: 'unset' }} />
      <h3 className={`pt3 ${customMessage === 'Your song is loading' ? 'video-player__no-screen--message' : ''}`}>{customMessage}</h3>
    </div>
  </div>
);

NoVideoScreen.propTypes = {
  customMessage: PropTypes.string,
};

export const LoadingSong = () => (<NoVideoScreen customMessage='Your song is loading' />);
