import React from 'react';
import PropTypes from 'prop-types';
import './container.less';

export const Container = ({ children }) => (
  <div className='container'>
    {children}
  </div>
);

Container.propTypes = { children: PropTypes.element };
