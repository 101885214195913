import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Container } from '../container';

import Logo from '../../../../../assets/img/logo.png';
import './footer.less';

export const Footer = () => {
  const userInfo = useSelector((state) => state.userInfo);
  const year = new Date().getFullYear();
  return (
    <div className='footer bc__secondary'>
      <Container>
        <div className='footer__container'>
          <Link to='/'>
            <figure className='footer__logo'>
              <img src={Logo} alt='logo' />
            </figure>
          </Link>
          <ul>
            <li><Link to='/songs'>SONGS</Link></li>
            {!userInfo && <li><Link to='/signUp'>SIGN UP</Link></li>}
          </ul>
        </div>
        <div className='footer__container footer__text'>
          <p>
            Copyright
            {' '}
            {year}
            {' '}
            Play Like a Natural
          </p>
          <p>
            <a
              target='_top'
              href='mailto:request@playlikeanatural.com?Subject=Song Request:'
              className='underline p-4'
            >
              Make a suggestion
            </a>
            {' '}
            about a tab you would like to see on the site
          </p>
        </div>
      </Container>
    </div>
  );
};
