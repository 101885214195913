import { ERROR_MESSAGE, handleError, showSuccess } from './utils';

export const signUp = (signupData) => handleError(async (dispatch, getState, { api }) => {
  await api.signup(signupData);
  showSuccess({ text: 'Account created! Welcome to PLAN!' });

  return true;
}, ERROR_MESSAGE);

export const login = (loginData) => handleError(async (dispatch, getState, { api }) => {
  await api.login(loginData);

  return true;
}, ERROR_MESSAGE);

export const logout = () => handleError(async (dispatch, getState, { api }) => {
  await api.logout();
  return true;
}, ERROR_MESSAGE);

export const resetPassword = ({ email }) => handleError(async (dispatch, getState, { api }) => {
  await api.requestPasswordReset(email);
  showSuccess({ text: 'A password reset email has been sent to the provided email.' });
  return true;
}, 'Could not send the email reset password');

export const setNewPassword = (newPasswordData) => handleError(async (dispatch, getState, { api }) => {
  await api.setPassword(newPasswordData);
  showSuccess({ text: 'Password restored' });
  return true;
}, 'Could not complete the restoration process');
