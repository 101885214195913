export const SCREEN_WIDTHS = {
  maxWidth: 1600,
  normalWidth: 960,
  normalScreenOffset: 24,
};

export const SLOW_MODE = 1;
export const NORMAL_MODE = 0;
export const NORMAL_TRACK = 0;
export const BACKING_TRACK = 1;
export const TAB_LINE_THICKNESS = 1;

export const TAB_BOARD_TO_SCREEN_RATIO = 0.10;
// 0.08 - Keep 8% of screen height free for bottom controls
export const VIDEO_TO_SCREEN_RATIO = 1 - TAB_BOARD_TO_SCREEN_RATIO - 0.08;

export const TAB_STAFF_TO_BOARD_RATIO = 0.35;
export const TAB_TOP_TO_BOARD_RATIO = 0.5;
/* 10 (original repeater bracket height) / 280 (original tab board height) */
export const REPEATER_BRACKET_TO_BOARD_RATIO = 0.035;
// only 3 rows are needed, using 3.5 to provide some spacing between content
export const TOP_CONTENT_TO_BOARD_RATIO = TAB_TOP_TO_BOARD_RATIO / 3.5;

// the white margin top offset between the bottom of the video and the top of the highest tabs board element (usually a repeater bracket)
export const TOP_CONTENT_MARGIN = 12; // px
// aux assets are volta brackets, bow up/down symbols, chords, etc.
export const TOP_CONTENT_AUX_ASSETS_MARGIN = 10; // px
export const TOP_CONTENT_TIE_ASSET_MARGIN = 9; // px

export const REPEATER_ASSET_HEIGHT_TO_WIDTH_RATIO = 0.45;
export const REPEATER_DOUBLE_OFFSET = 15;
export const TIME_SIGNATURE_ASSET_HEIGHT_TO_WIDTH_RATIO = 0.395;

export const NOTE_ALTERNATIVE_FILL = 'white';
export const NOTE_FILL = 'black';

export const VERTICAL_LINE_WIDTH = 3;

export const SPEED_OPTIONS = [
  { key: 'ad', value: NORMAL_MODE, text: 'Normal speed' },
  { key: 'ae', value: SLOW_MODE, text: 'Half speed' },
];
