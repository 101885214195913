/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect } from 'react';
import Hls, { Config } from 'hls.js';

export const ReactHlsPlayer = ({
  hlsConfig = Config,
  playerRef = React.createRef(),
  src,
  autoPlay,
  trackName,
  setHasBackingTrack,
  isVideoLoading,
  onTrackSwitched,
  onTrackSwitching,
  ...props
}) => {
  const [hlsObject, setHlsObject] = React.useState();

  useEffect(() => {
    function initializePlayer() {
      const newHls = new Hls({
        enableWorker: false,
        initialLiveManifestSize: 2,
        maxBufferLength: 120,
        ...hlsConfig,
      });

      if (playerRef.current != null) {
        newHls.attachMedia(playerRef.current);
      }

      newHls.on(Hls.Events.MEDIA_ATTACHED, () => {
        newHls.loadSource(src);

        newHls.on(Hls.Events.MANIFEST_PARSED, () => {
          if (autoPlay) {
            playerRef?.current
              ?.play()
              .catch(() => console.log(
                'Unable to autoplay prior to user interaction with the dom.',
              ));
          }
        });
      });

      newHls.on(Hls.Events.ERROR, (event, data) => {
        if (data.fatal) {
          switch (data.type) {
            case Hls.ErrorTypes.NETWORK_ERROR:
              newHls.startLoad();
              break;
            case Hls.ErrorTypes.MEDIA_ERROR:
              newHls.recoverMediaError();
              break;
            default:
              initializePlayer();
              break;
          }
        }
      });

      setHlsObject(newHls);
    }

    // Check for Media Source support
    if (Hls.isSupported()) {
      initializePlayer();
    }

    return () => {};
  }, [autoPlay, hlsConfig, playerRef, src]);

  React.useEffect(() => () => {
    if (hlsObject) hlsObject.destroy();
  }, [hlsObject]);

  React.useEffect(() => {
    if (!hlsObject) return;

    /* hlsObject.audioTracks returns empty array with the first render.
    Must force the player rerender with isVideoLoading flag to get the tracks.
    This rerender happens only once during initialize */
    if (hlsObject.audioTracks.length > 0) {
      const hasBackingTrack = hlsObject.audioTracks.some(({ name }) => name.includes('back'));
      setHasBackingTrack(hasBackingTrack);
    }

    const newTrack = hlsObject.audioTrackController.tracks.find(({ name }) => {
      if (trackName === 'backing') {
        return ['back', 'backing'].includes(name);
      }

      if (trackName === 'instrument') {
        return ['audio', 'instrument'].includes(name);
      }

      return name === 'both';
    });

    // If track does not exist, do not change audioTrack
    if (newTrack) {
      hlsObject.audioTrack = newTrack.id;
    }
  }, [hlsObject, trackName, setHasBackingTrack, isVideoLoading]);

  // Handle fallback HTML video element
  React.useEffect(() => {
    if (hlsObject) return () => {};

    /* Iterate though video.audioTracks
     * @see https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/audioTracks
     */
    for (let i = 0; i < playerRef.current?.audioTracks?.length; i++) {
      switch (trackName) {
        case 'backing':
          playerRef.current.audioTracks[i].enabled = (
            ['back', 'backing'].includes(playerRef.current.audioTracks[i].label)
          );
          break;
        case 'instrument':
          playerRef.current.audioTracks[i].enabled = (
            ['audio', 'instrument'].includes(playerRef.current.audioTracks[i].label)
          );
          break;
        default:
          playerRef.current.audioTracks[i].enabled = (
            playerRef.current.audioTracks[i].label === trackName
          );
          break;
      }
    }

    /* Tracks are added once the video is loaded
     * i.e. after user interaction in iOS
     * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/video#detecting_track_addition_and_removal
     */
    const refAudioTrack = playerRef.current?.audioTracks;

    const handleTracksAdded = (event) => {
      event.track.enabled = event.track.label === trackName;

      if (event.track.label.includes('back')) {
        setHasBackingTrack(true);
      }
    };

    refAudioTrack?.addEventListener('addtrack', handleTracksAdded);

    return () => {
      refAudioTrack?.removeEventListener('addtrack', handleTracksAdded);
    };

  }, [hlsObject, playerRef, trackName, setHasBackingTrack]);

  /* Handle HLS Track Switch
   *
   * Note:
   * HTML <video /> has no way of telling when audio track is actually
   * loaded and switched
   */
  useEffect(() => {
    if (!(hlsObject && onTrackSwitched && onTrackSwitching)) return () => {};

    hlsObject.on(Hls.Events.AUDIO_TRACK_SWITCHED, onTrackSwitched);
    hlsObject.on(Hls.Events.AUDIO_TRACK_SWITCHING, onTrackSwitching);

    return () => {
      hlsObject.off(Hls.Events.AUDIO_TRACK_SWITCHED, onTrackSwitched);
      hlsObject.off(Hls.Events.AUDIO_TRACK_SWITCHING, onTrackSwitching);
    };
  }, [hlsObject, onTrackSwitched, onTrackSwitching]);

  // If Media Source is supported, use HLS.js to play video
  if (Hls.isSupported()) return <video ref={playerRef} {...props} />;

  // Fallback to using a regular video player if HLS is supported by default in the user's browser
  // With the preload='metadata' property can set the thumbnail to the video with a fragment of the video in the second defined in #t=
  return <video ref={playerRef} src={`${src}#t=0.05`} autoPlay={autoPlay} preload='metadata' {...props} />;
};
