import PropTypes from 'prop-types';
import editIcon from 'img/edit.png';
import levelIcon from 'img/level.png';
import levelIconWhite from 'img/level-white.png';
import playIcon from 'img/play.png';
import settingsIcon from 'img/settings.png';
import adminsIcon from 'img/users.png';
import songIcon from 'img/song.png';
import importIcon from 'img/upload.png';
import playVideoIcon from 'img/play-video.svg';
import pauseVideoIcon from 'img/pause-video.svg';
import editDots from 'img/dots.png';
import replayVideoIcon from 'img/replay.svg';
import systemUpdate from 'img/system_update.svg';
import closeIcon from 'img/close.svg';
import levelGray from 'img/level-gray.svg';
import mandolin from 'img/mandolin.png';

const iconTypes = {
  'edit': editIcon,
  'level': levelIcon,
  'level-white': levelIconWhite,
  'play': playIcon,
  'admin': adminsIcon,
  'song': songIcon,
  'settings': settingsIcon,
  'import': importIcon,
  'play-video': playVideoIcon,
  'pause-video': pauseVideoIcon,
  'dots': editDots,
  'replay-video': replayVideoIcon,
  'system-update': systemUpdate,
  'close': closeIcon,
  'level-gray': levelGray,
  'mandolin': mandolin,
};

export const CustomIcon = ({ name = 'dashboard', className, ...props }) => (
  <>
    <img src={iconTypes[name]} alt={name} className={`pointer ${className}`} {...props} />
  </>
);

CustomIcon.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
};
