import React from 'react';
import PropTypes from 'prop-types';
import { Layer, Stage } from 'react-konva';

import TabStaff from './TabStaff';
import NoteMapper from './NoteMapper';

import TabBoardDimensionsContext from './TabBoardDimensionsContext';

function TabStage({ notes, layoutLine, isFullScreen }) {
  const boardDimensions = React.useContext(TabBoardDimensionsContext);
  const { width, height } = boardDimensions;
  /* This is a workaround:
   * react-konva does not support classic support for context,
   * @see https://github.com/konvajs/react-konva/issues/188
   */
  return (
    <Stage className='video-player__stage' width={isFullScreen ? width - 8 : width} height={height * 1.2}>
      <TabBoardDimensionsContext.Provider value={boardDimensions}>
        <Layer>
          <TabStaff layoutLine={layoutLine} />
          <NoteMapper notes={notes} layoutLine={layoutLine} />
        </Layer>
      </TabBoardDimensionsContext.Provider>
    </Stage>
  );
}

TabStage.propTypes = {
  notes: PropTypes.arrayOf(PropTypes.array),
  layoutLine: PropTypes.number,
  isFullScreen: PropTypes.bool.isRequired,
};

TabStage.defaultProps = {
  notes: [],
  layoutLine: 0,
};

export default TabStage;
