import { memo } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import {
  Form, Input,
} from 'semantic-ui-react';
import { Button } from 'site/components/atoms/button/button';
import { Popup } from 'controls';
import { useFieldset, useDispatchCallback } from 'controls/hooks';
import { resetPassword } from 'site/actions/authentication';
import './forgotPassword.less';
import { forgotPasswordSchema } from 'controls/schemas/auth';

const forgotForm = { email: '' };

export const ForgotPasswordForm = memo(() => {
  const { fields: { email }, submit, loading } = useFieldset({ schema: forgotPasswordSchema, source: forgotForm, onSubmit: useDispatchCallback(resetPassword) });

  return (
    <div className='form flex flex-column min-vh-100 w-100 pa3 justify-center'>
      <Helmet title={`Forgot password | ${window.__ENVIRONMENT__.APP_NAME}`} />
      <div className='form--block center flex-column items-center justify-center self-center'>
        <div className='form__title'>Forgot password</div>
        <Form onSubmit={submit} className='forgot__form'>
          <h4 className='mb3'> Write the email associated with your account</h4>
          <Form.Field error={email.errored}>
            <label>email</label>
            <Popup message={email.message} enabled={email.errored} open={!!(email.errored)}>
              <Input fluid autoComplete='email' value={email.value} onChange={email.onChange} />
            </Popup>
          </Form.Field>
          <Button color='primary' className='forgot__button' disabled={loading} loading={loading}>
            RESET PASSWORD
          </Button>
          <Link to='/login' className='forgot__link'>Return to sign in</Link>
        </Form>
      </div>
    </div>
  );
});
