import React from 'react';
import { Text } from 'react-konva';

import { NOTE_FILL } from '../constants';
import { TOP_CONTENT_MARGIN } from '../constants/constants';

import TabBoardDimensionsContext from './TabBoardDimensionsContext';

function TabLetters() {
  const {
    width: boardWidth,
    staffHeight,
  } = React.useContext(TabBoardDimensionsContext);

  const fontSize = Math.floor(staffHeight / 2.4);

  return (
    <>
      <Text
        text='T'
        x={boardWidth * 0.0075}
        y={(staffHeight / 6 - fontSize / 2) * 0.1 + TOP_CONTENT_MARGIN}
        fontSize={fontSize}
        fill={NOTE_FILL}
      />
      <Text
        text='A'
        x={boardWidth * 0.0075}
        y={(staffHeight / 2 - fontSize / 2) * 1.15 + TOP_CONTENT_MARGIN}
        fontSize={fontSize}
        fill={NOTE_FILL}
      />
      <Text
        text='B'
        x={boardWidth * 0.0075}
        y={(5 * (staffHeight / 6) - fontSize / 2) * 1.075 + TOP_CONTENT_MARGIN}
        fontSize={fontSize}
        fill='black'
      />
    </>
  );
}

export default React.memo(TabLetters);
