import React from 'react';
import PropTypes from 'prop-types';

import { Rect, Text } from 'react-konva';

import { KanvaImage } from './images';

import { getStringYPositions } from '../videoPlayerUtils';

import TabBoardDimensionsContext from './TabBoardDimensionsContext';
import TabNote from './TabNote';

import {
  NOTE_FILL,
  TOP_CONTENT_TO_BOARD_RATIO,
} from '../constants';
import { TOP_CONTENT_MARGIN, TOP_CONTENT_AUX_ASSETS_MARGIN, TOP_CONTENT_TIE_ASSET_MARGIN } from '../constants/constants';

const noteHasBowUp = (note) => note.bow === 'up';
const noteHasBowDown = (note) => note.bow === 'down';
const noteHasFirstVoltaIndex = (note) => (
  note.volta && note.pitch && note.location.idx === 1
);
const noteDrawsVoltaHorizontal = (note) => (
  note.volta && (
    (
      note.pitch
      && note.location.idx > 1
      && note.location.idx !== note.location.total
    )
    || note.isRest
  )
);

const noteHasLastVoltaIndex = (note) => (
  note.volta && note.pitch && note.location.idx === note.location.total
);
const noteHasTieStart = (note) => note?.tie?.$?.type === 'start';
const noteHasTieStop = (note) => note?.tie?.$?.type === 'stop';
const noteHasTab = (note) => !note.isLine && !note.isSymbol && !note.isRest;

const isMeasureFirstNote = (note) => note.location.idx === 1;
const isMeasureLastNote = (note) => note.location.idx === note.location?.total;

function GenericNote({ note, noteSpacing, noteXPos }) {
  const {
    height: boardHeight,
    topHeight: boardTopHeight,
    width: playerWidth,
  } = React.useContext(TabBoardDimensionsContext);

  const topContentHeight = boardHeight * TOP_CONTENT_TO_BOARD_RATIO;

  const stringYPositions = React.useMemo(() => getStringYPositions(playerWidth), [playerWidth]);

  // make tie asset as big as the space between to strings
  const spaceBetweenStrings = stringYPositions['2'] - stringYPositions['1'];
  const tieAssetHeight = spaceBetweenStrings * 2;
  const tabFontSize = topContentHeight;
  const tabFontWidth = tabFontSize / 2;
  const voltaTopMargin = Math.round(topContentHeight / 4);

  return (
    <>
      {/* bow up-down symbols */}
      {noteHasBowUp(note) && (
        <Text
          x={noteXPos - topContentHeight / 3}
          fontSize={topContentHeight}
          fontFamily='Arial'
          scaleY={1.5}
          text='V'
          y={topContentHeight * 1.8 + TOP_CONTENT_AUX_ASSETS_MARGIN}
          fill={NOTE_FILL}
        />
      )}
      {noteHasBowDown(note) && (
        <KanvaImage
          imageSrc='down-bow.png'
          width={topContentHeight}
          height={topContentHeight}
          y={topContentHeight * 2 + TOP_CONTENT_AUX_ASSETS_MARGIN}
          x={noteXPos - topContentHeight / 2}
        />
      )}

      {/* volta brackets */}
      {noteHasFirstVoltaIndex(note) && (
        <>
          <Rect
            fill={NOTE_FILL}
            height={1}
            key={`${note.id}-volta-start-horizontal`}
            width={noteSpacing + tabFontWidth}
            x={noteXPos - tabFontWidth}
            y={voltaTopMargin + TOP_CONTENT_AUX_ASSETS_MARGIN}
          />
          <Rect
            fill={NOTE_FILL}
            key={`${note.id}-volta-start-vertical`}
            height={topContentHeight}
            width={1}
            x={noteXPos - tabFontWidth}
            y={voltaTopMargin + TOP_CONTENT_AUX_ASSETS_MARGIN}
          />
          <Text
            fill={NOTE_FILL}
            key={`${note.id}-volta-start-text`}
            text={`${note.volta}.`}
            fontFamily='Times New Roman'
            fontSize={topContentHeight * 0.7}
            fontStyle='BOLD'
            x={noteXPos + topContentHeight / 4 - tabFontWidth}
            y={voltaTopMargin + TOP_CONTENT_AUX_ASSETS_MARGIN + topContentHeight * 0.175}
          />
        </>
      )}

      {noteDrawsVoltaHorizontal(note) && (
        <Rect
          fill={NOTE_FILL}
          height={1}
          key={`${note.id}-volta-horizontal-top`}
          width={noteSpacing}
          x={noteXPos}
          y={voltaTopMargin + TOP_CONTENT_AUX_ASSETS_MARGIN}
        />
      )}

      {noteHasLastVoltaIndex(note) && (
        <>
          <Rect
            fill={NOTE_FILL}
            height={1}
            key={`${note.id}-volta-end-horizontal`}
            width={noteSpacing / 2}
            x={noteXPos}
            y={voltaTopMargin + TOP_CONTENT_AUX_ASSETS_MARGIN}
          />
          <Rect
            fill={NOTE_FILL}
            key={`${note.id}-volta-end-vertical`}
            height={topContentHeight}
            width={1}
            x={noteXPos + noteSpacing / 2 - 1}
            y={voltaTopMargin + TOP_CONTENT_AUX_ASSETS_MARGIN}
          />
        </>
      )}
      {/* end volta brackets */}

      {/* Chord above note */}
      {note.chordAbove && (
        <Text
          fontFamily='Georgia'
          x={noteXPos - topContentHeight / 3}
          y={topContentHeight + TOP_CONTENT_AUX_ASSETS_MARGIN}
          text={note.chordAbove}
          fill={NOTE_FILL}
          fontSize={topContentHeight}
        />
      )}

      {/* ties */}
      {noteHasTieStart(note) && !isMeasureLastNote(note) && (
        <KanvaImage
          imageSrc='tie.png'
          height={tieAssetHeight}
          width={noteSpacing}
          y={(note.name ? stringYPositions[note.name.string] : boardTopHeight) - TOP_CONTENT_TIE_ASSET_MARGIN}
          x={noteXPos}
        />
      )}
      {/* only paint this tie whenever the tie start was the last note measure, and the end is the first measure note */}
      {noteHasTieStop(note) && isMeasureFirstNote(note) && (
        <KanvaImage
          imageSrc='tie.png'
          height={tieAssetHeight}
          width={noteSpacing * 2}
          y={(note.name ? stringYPositions[note.name.string] : boardTopHeight) - TOP_CONTENT_TIE_ASSET_MARGIN}
          x={
            noteXPos
            // make tie start from previous tied note
            - noteSpacing * 2
          }
        />
      )}

      {/* tabs */}
      {noteHasTab(note) && (
        <TabNote
          key={`note-${note.id}-${note.name.string}`}
          isActive={note.color === NOTE_FILL}
          isChord={note.isChord}
          height={spaceBetweenStrings}
          text={note.name ? note.name.position : ''}
          y={(note.name ? stringYPositions[note.name.string] : boardTopHeight) + TOP_CONTENT_MARGIN}
          x={noteXPos}
        />
      )}
    </>
  );
}

GenericNote.propTypes = {
  note: PropTypes.objectOf(PropTypes.any).isRequired,
  noteXPos: PropTypes.number.isRequired,
  noteSpacing: PropTypes.number.isRequired,
};

export default React.memo(GenericNote);
