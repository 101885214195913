import React from 'react';
import PropTypes from 'prop-types';

import { Rect, Text } from 'react-konva';

import { NOTE_FILL, NOTE_ALTERNATIVE_FILL } from '../constants';

import Pick from './Pick';

function TabNote({
  isChord,
  isActive,
  height,
  text,
  x,
  y,
}) {
  const fontSize = height;
  const backgroundHeight = height * 0.75;

  return (
    <>
      {!isActive && (
        <Rect
          height={backgroundHeight}
          fill={NOTE_ALTERNATIVE_FILL}
          width={height * 0.8}
          x={x - height * 0.4}
          y={y - backgroundHeight / 2}
        />
      )}
      {isActive && isChord && (
        <Rect
          /* Using Math.floor() and Math.ceil()
           * to make chord note backgrounds overlap */
          height={Math.ceil(height)}
          fill={NOTE_FILL}
          width={height * 0.8}
          x={x - height * 0.4}
          y={Math.floor(y - height * 0.45)}
        />
      )}
      {isActive && !isChord && (
        <Pick
          height={fontSize}
          color={isActive ? NOTE_FILL : NOTE_ALTERNATIVE_FILL}
          x={x - fontSize * (text !== 0 ? 1.08 : 1.1)}
          y={y - fontSize * 0.15}
        />
      )}
      <Text
        fontSize={fontSize}
        text={text}
        // center text in line
        y={y - fontSize * 0.4}
        // center tab number
        x={Math.floor(x - fontSize * 0.25)}
        fill={isActive ? NOTE_ALTERNATIVE_FILL : NOTE_FILL}
      />
    </>
  );
}

TabNote.propTypes = {
  isActive: PropTypes.bool,
  isChord: PropTypes.bool,
  height: PropTypes.number.isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
};

TabNote.defaultProps = {
  isActive: false,
  isChord: false,
  text: '?',
};

export default React.memo(TabNote);
