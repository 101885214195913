import React from 'react';
import { levelRendererSmall } from 'controls/table';

export const defaultParams = {
  sortBy: 'updatedAt', sortDir: 'desc', search: '', page: '1',
};

export const stylesOptions = [
  { text: 'All', value: '' },
  { text: 'Rock', value: 'Rock' },
  { text: 'Acoustic', value: 'Acoustic' },
];

export const levelsOptions = [
  { text: <div>All</div>, value: '' },
  { text: <div>{levelRendererSmall({ cellData: 1 })}</div>, value: '1' },
  { text: <div>{levelRendererSmall({ cellData: 2 })}</div>, value: '2' },
  { text: <div>{levelRendererSmall({ cellData: 3 })}</div>, value: '3' },
];

export const keysOptions = [
  { text: 'All', value: '' },
  { text: 'C', value: 'C' },
  { text: 'C#', value: 'C#' },
  { text: 'D', value: 'D' },
  { text: 'D#', value: 'D#' },
  { text: 'E', value: 'E' },
  { text: 'F', value: 'F' },
  { text: 'F#', value: 'F#' },
  { text: 'G', value: 'G' },
  { text: 'G#', value: 'G#' },
  { text: 'A', value: 'A' },
  { text: 'A#', value: 'A#' },
  { text: 'B', value: 'B' },
  { text: 'B#', value: 'B#' },
];
