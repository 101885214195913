import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { setBackground } from 'site/actions/background';
import { Button } from 'site/components/atoms/button/button';
import { Header } from './header';
import { Footer } from './footer';

import './layout.less';

export const Layout = ({ children }) => {
  const userInfo = useSelector((state) => state.userInfo);
  const secondaryBackground = useSelector((state) => state.siteInfo.secondaryBackground);
  const location = useLocation();
  const dispatch = useDispatch();
  const currentLocation = location.pathname.split('/')[1];
  const hasNoLayout = currentLocation === 'song-no-layout';
  const hasInSecondaryBackgroundRoute = currentLocation === 'login' || currentLocation === 'signUp' || currentLocation === 'forgot-password' || currentLocation === 'new-password' || (currentLocation === 'song' && !userInfo);
  const useSecondaryBackground = secondaryBackground || (hasInSecondaryBackgroundRoute && currentLocation !== 'song');
  const isHome = currentLocation === '';
  useEffect(() => {
    if (secondaryBackground && (!hasInSecondaryBackgroundRoute && currentLocation !== 'song')) {
      dispatch(setBackground(false));
    }
  }, [currentLocation, dispatch, hasInSecondaryBackgroundRoute, secondaryBackground]);

  const isSongsOrSong = window.location.href.indexOf('songs') > -1 || window.location.href.indexOf('song') > -1;

  return (
    <div className={`${isHome ? 'mandolin-img' : ''} ${useSecondaryBackground ? 'bc__dark' : ''}`}>
      {!hasNoLayout && isSongsOrSong ? <></> : <Header useSecondaryBackground={useSecondaryBackground} />}
      {children}
      {!userInfo && location.pathname !== '/signUp' && !hasNoLayout && isSongsOrSong ? <></>
        : (
          <div className='layout__sign-up'>
            <p className='layout__sign-up__text'>Sign up for FREE access</p>
            <Link to='/signUp'>
              <Button color='secondary'>
                Sign Up
              </Button>
            </Link>
          </div>
        )}
      {!hasNoLayout && isSongsOrSong ? <></> : <Footer />}
    </div>
  );
};

Layout.propTypes = { children: PropTypes.node };
