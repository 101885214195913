import { ERROR_MESSAGE, handleError } from './utils';

export const initializeErrored = (errorMessage) => ({ type: 'SET_ERRORED', message: errorMessage });

export const initialize = () => handleError(async (dispatch, getState, { api }) => {
  dispatch({ type: 'INITIALIZE', running: true });

  await api.initialize(dispatch);

  // Uncomment when necessary
  /* dispatch(downloadInitialData()); */

  dispatch({ type: 'INITIALIZE', running: false });
}, ERROR_MESSAGE);

/* export const downloadInitialData = () => handleError(async (dispatch) => {
  const operations = [];

  await Promise.all(operations);
  return true;
}, 'A problem occurred when downloading some of the initial data from the server'); */
