import _ from 'lodash';
import { Link } from 'react-router-dom';
import { Label, Icon } from 'semantic-ui-react';
import { CustomIcon } from 'site/components/atoms/icons';
import { showError } from 'site/actions/utils';
import { formatDate } from '../utils';
import { Popup } from '../popup';

export const dateRenderer = ({ cellData, columnData = {} }) => (cellData ? formatDate(cellData, columnData.format) : undefined);
export const labelRenderer = ({ cellData, columnData = {}, message }) => <Popup inverted message={message} enabled={!!message}><Label content={cellData} {...columnData} /></Popup>;// eslint-disable-line react/prop-types
export const iconRenderer = (name, size = 'big') => <Icon name={name} size={size} />;
export const levelRenderer = ({ cellData }) => (
  <div className='flex justify-center'>
    {Array(cellData).fill(1).map((e, id) => (
      <div className='radio-stack'>
        <div className='radio-child'>
          <CustomIcon name='level-white' key={e + id.toString()} className='level-icon' />
        </div>
      </div>
    ))}
    {Array(3 - cellData).fill(1).map((e, id) => (
      <input
        type='radio'
        disabled
        key={e + id.toString()}
        className='radio'
      />
    ))}
  </div>
);
export const levelRendererSmall = ({ cellData }) => (
  <div className='flex justify-center items-center'>
    {Array(cellData).fill(1).map((e, id) => (
      <div className='radio-stack-small'>
        <div className='radio-child-small'>
          <CustomIcon name='level-white' key={e + id.toString()} className='level-icon-small' />
        </div>
      </div>
    ))}
    {Array(3 - cellData).fill(1).map((e, id) => (
      <div
        key={e + id.toString()}
        className='radio-small'
      />
    ))}
  </div>
);
// Todo: add a validation to know if the user can access a restricted song
export const playLinkRenderer = ({ cellData, rowData }, user) => {
  const noAccess = rowData.restricted && !user;
  const validatePermissions = () => {
    if (noAccess) {
      showError({ title: 'Please sign in to access this content. Creating an account is free!' });
    }
  };

  const title = encodeURIComponent(rowData.title.trim().replaceAll(' ', '_'));
  return (
    <Link to={noAccess ? '/songs' : `/song/${cellData}/${title}`} onClick={validatePermissions} className='black'>
      <Icon name='play circle' size='big' color='orange' />
    </Link>
  );
};

export const pdfLinkRenderer = ({ cellData }) => {
  const pdfLink = _.get(cellData, 'url', null);

  if (pdfLink) {
    return (
      <a href={pdfLink} target='_blank' rel='noopener noreferrer'>
        <Icon name='file pdf outline' size='large' color='red' />
      </a>
    );
  }
};
