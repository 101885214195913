import React from 'react';
import PropTypes from 'prop-types';
import { KanvaImage } from './images';
import TabBoardDimensionsContext from './TabBoardDimensionsContext';

import { VERTICAL_LINE_WIDTH, REPEATER_ASSET_HEIGHT_TO_WIDTH_RATIO, TOP_CONTENT_MARGIN } from '../constants';
import { REPEATER_DOUBLE_OFFSET } from '../constants/constants';

function Repeater({ direction, x, double }) {
  let src = direction === 'forward' ? 'repeater-start.png' : 'repeater-end.png';

  // this an asset that is a backward repeater followed by a forward repeater
  // this way the repeaters are tied together and don't overlap
  if (double) {
    src = 'repeater-end-start.png';
  }

  const { topHeight, staffHeight } = React.useContext(
    TabBoardDimensionsContext,
  );

  const assetHeight = staffHeight;
  const doubleRepeaterAssetOffset = double ? REPEATER_DOUBLE_OFFSET : 0;
  const assetWidth = assetHeight * REPEATER_ASSET_HEIGHT_TO_WIDTH_RATIO + doubleRepeaterAssetOffset;
  const calculatedX = direction === 'forward'
    ? x + assetWidth
    : x - assetWidth + VERTICAL_LINE_WIDTH;

  return (
    <KanvaImage
      imageSrc={src}
      height={assetHeight}
      width={assetWidth}
      x={calculatedX}
      y={topHeight + TOP_CONTENT_MARGIN}
    />
  );
}

Repeater.propTypes = {
  direction: PropTypes.oneOf(['forward', 'backward']).isRequired,
  x: PropTypes.number.isRequired,
  double: PropTypes.bool,
};

export default React.memo(Repeater);
