import { parseString } from 'xml2js';
import _ from 'lodash';

/* Dot value extends the note duration by half
e.g. if it's quarter is duration will be
quarter + eighth (5/16) */
const setDotValue = (note) => {
  switch (note.type) {
    case 'half':
      return 'quarter';
    case 'quarter':
      return 'eighth';
    case 'eighth':
      return '16th';
    default:
  }
};

const setChordTime = (sng) => {
  // assign chord notes the same time than
  // the notes from the same chord note
  const song = sng;

  for (let i = 0; i < sng.length; i++) {
    if (sng[i].isChord && i > 0) {
      if (sng[i].defaultXPos === sng[i - 1].defaultXPos) {
        song[i].time = sng[i - 1].time;
      }
    }
  }
  return song;
};

const updateObjsToArrays = (finalSong) => {

  // turn song into an array of arrays with objects
  let updatedSong = [];
  const song = finalSong;
  for (let i = 0; i < song.length; i++) {
    song[i].id = i;

    // push chord notes into its array
    if (song[i]?.chordIdx === 'last') {
      updatedSong[i] = [song[i]];

      for (let j = 1; j < 4; j++) { // Check last 3 notes in case they are same chord
        if (song[i - j]?.isChord
           && song[i - j]?.defaultXPos === song[i].defaultXPos
           && song[i - j]?.measure === song[i].measure) {
          updatedSong[i].unshift(song[i - j]);
        }
      }
    }

    // push regular not-chord notes
    if (!song[i]?.chordIdx) {
      updatedSong[i] = [song[i]];
    }
  }
  // remove empty indexes
  updatedSong = updatedSong.filter((note) => note);

  return updatedSong;
};

const incrementTimer = (noteDuration, dot = false, btpm, isTuplet, songTime) => {
  let msPerBeat = 60 / btpm;
  let time = songTime;
  if (isTuplet) {
    msPerBeat *= (2 / 3);
  }

  // for a 4/4 song
  const noteLength = {
    'complete': msPerBeat * 4,
    'whole': msPerBeat * 4,
    'half': msPerBeat * 2,
    'quarter': msPerBeat * 1,
    'eighth': msPerBeat * 0.5,
    '16th': msPerBeat * 0.25,
    '32nd': msPerBeat * 0.125,
  };

  let noteMsDuration = 0;

  switch (noteDuration) {
    case 'complete':
      noteMsDuration = !dot ? noteLength.complete : noteLength.complete * 1.5;
      break;
    case 'whole':
      noteMsDuration = !dot ? noteLength.whole : noteLength.whole * 1.5;
      break;
    case 'half':
      noteMsDuration = !dot ? noteLength.half : noteLength.half * 1.5;
      break;
    case 'quarter':
      noteMsDuration = !dot ? noteLength.quarter : noteLength.quarter * 1.5;
      break;
    case 'eighth':
      noteMsDuration = !dot ? noteLength.eighth : noteLength.eighth * 1.5;
      break;
    case '16th':
      noteMsDuration = !dot ? noteLength['16th'] : noteLength['16th'] * 1.5;
      break;
    case '32nd':
      noteMsDuration = !dot ? noteLength['32nd'] : noteLength['32nd'] * 1.5;
      break;
    default:
      break;
  }
  time += noteMsDuration;
  return time;
};

const getLastNoteLength = (song) => {

  const onlyNotesAndRests = getOnlyNotesAndRests(song);
  const lastNoteLength = onlyNotesAndRests[onlyNotesAndRests.length - 1].duration;

  return lastNoteLength;
};

const getOnlyNotesAndRests = (song) => song.filter((el) => el.isRest || el.name);

/* either increment or return same time
depending the note type */
const setTime = (counter, hasDot, bpm, isTuplet, song, time) => {
  if (counter === 1) return 0;
  let songTime = time;

  const partialSong = getOnlyNotesAndRests(song);

  // this is for chords
  // avoid adding time for each
  // note in the chord
  if (
    counter > 1
        && partialSong[partialSong.length - 2]?.addToTimer !== undefined
  ) {
    return songTime;
  }

  // increment time depending the note value
  const lastNoteDuration = getLastNoteLength(song);
  songTime = incrementTimer(lastNoteDuration, hasDot, bpm, isTuplet, songTime);

  return songTime;
};

const makeSymbol = () => {
  const symbols = [];
  const symbolCount = 2; // show TAB signal and song time (4/4 symbol)

  for (let i = 0; i < symbolCount; i++) {
    const symbol = {
      isLine: false,
      color: 'white',
      show: false,
      duration: 0,
      time: 0,
      isSymbol: true,
    };
    symbols.push(symbol);
  }

  return symbols;
};

export const makeLine = (count, idx, layoutBreakNoteIdx, locationData = null, firstNoteHasBeenPlayed) => {
  const lines = [];
  // starting measure line
  for (let i = 0; i < count; i++) {
    lines.push({
      isLine: true,
      color: 'white',
      duration: 0,
      time: 0,
      show: false,
      measure: idx === 'end' ? idx : idx + 1,
      location: { layoutBreakNoteIdx, ...locationData, firstNoteHasBeenPlayed },
    });
  }
  return lines;
};

const makeRepeater = (idx, backward = false, locationData = null, repeaterCounter) => {
  let repeaterCount = repeaterCounter;

  repeaterCount += 1;
  const repeater = {
    repeater: true,
    repeaterCount: repeaterCounter,
    direction: !backward ? 'forward' : 'backward',
    color: 'white',
    duration: 0,
    time: 0,
    show: false,
    measure: idx + 1,
    location: locationData || { idx },
  };

  return { repeater, repeaterCount };
};

const makeRest = (hasDot, restLn, count, btpm, nt, idx, volta, layoutBreakNoteIdx, layoutBreakLineIdx, song, songTime) => {
  const time = setTime(count, hasDot, btpm, false, song, songTime);

  const restNote = {
    color: 'white',
    show: false,
    isRest: true,
    volta,
    dot: hasDot,
    duration: restLn,
    measure: idx + 1,
    location: { layoutBreakNoteIdx, layoutBreakLineIdx },
    staff: nt?.staff ?? false,
    time,
  };

  return { restNote, time };
};

const getBowDirection = (note) => {
  let direction = null;

  // check if contains bow
  if (note?.notations?.technical) {

    // check which kind of bow is
    // remember that bows output is and empty string ("")
    // so isString method is required
    if (note?.notations?.technical['up-bow']) {
      direction = 'up';
    } else if (note?.notations?.technical['down-bow']) {
      direction = 'down';
    }
  }
  return direction;
};

const getMeasureLength = (ms) => {
  let length = 0;
  for (let i = 0; i < ms.note.length; i++) {
    if (ms.note[i]?.notations?.technical?.fret) {
      length += 1;
    }
  }
  return length;
};

// chords above the notes
const getChords = (ms) => {
  let counter = 0;
  let chord = null;
  let allChords = [];

  if (ms.$$) {
    for (let i = 0; i < ms.$$.length; i++) {
      if (chord && counter === 1) {
        allChords = [...allChords, {
          defaultXPos: ms.$$[i].$['default-x'],
          chord,
        }];
        chord = null;
        counter -= 1;
      }
      if (ms?.$$[i]['#name'] === 'direction') {
        chord = ms?.$$[i]['direction-type']?.words?._;
        counter += 1;
      }
    }
  }
  return allChords;
};

const setNoteAbove = (ntXPos, chordObj) => {
  // filter notes with chords above
  const matchXPos = chordObj.filter((el) => el.defaultXPos === ntXPos);

  if (matchXPos.length > 0) {
    return matchXPos[0].chord;
  }

  return false;
};

/* assign id and line note total */
const updateSongData = (mutatedSong) => {
  const idSong = [];

  let previousLayoutBreakNoteIdx = 0;
  let previousLineNoteTotal = 0;

  for (let i = 0; i < mutatedSong.length; i++) {
    const note = mutatedSong[i][0];

    // since repeaters have not layoutBreakNoteIdx nor lineNoteTotal
    // get them from previous notes
    const layoutBreakNoteIdx = i === 0
      ? 0
      : (note?.location?.layoutBreakNoteIdx || (previousLayoutBreakNoteIdx + 1));
    previousLayoutBreakNoteIdx = layoutBreakNoteIdx;

    const lineNoteTotal = note?.location?.lineNoteTotal || previousLineNoteTotal;
    previousLineNoteTotal = layoutBreakNoteIdx;

    // eslint-disable-next-line no-loop-func
    idSong[i] = mutatedSong[i].map((el) => ({
      ...el,
      id: i,
      location: {
        ...el.location,
        layoutBreakNoteIdx,
        lineNoteTotal,
      },
    }));
  }
  return idSong;
};

const getVoltaNumber = (measureBarline) => {
  let voltaNumber = false;

  if (measureBarline.length > 1) {
    voltaNumber = measureBarline.map((msBar) => msBar?.ending?.$?.number);
    voltaNumber = voltaNumber[voltaNumber.length - 1]; // avoid array output
  } else {
    voltaNumber = measureBarline?.ending?.$?.number;
  }

  if (voltaNumber === undefined) {
    voltaNumber = false;
  }

  return voltaNumber;
};

/* perfom a backwards loop and find
  the first note from the last line break
  and passing that note the total count from the line
  */
const setNotesPerLineCountData = (layoutBreakLineIdx, song) => {
  let lineNoteTotal = null;
  let hasFoundLastNote = 0;
  const partialSong = song;

  for (let i = partialSong.length - 1; i >= 0; i--) {
    // detect tied repeaters
    if (partialSong[i]?.direction === 'backward' && partialSong[i + 1]?.direction === 'forward' && i !== 0) {
      partialSong[i] = {
        ...partialSong[i],
        hasForwardRepeaterComing: true,
      };
    }

    // removing measure separation lines when tied repeaters are present
    if (partialSong[i]?.direction === 'forward' && partialSong[i - 1]?.direction === 'backward' && i !== 0) {
      partialSong[i] = {
        ...partialSong[i],
        hasBackwardRepeaterBehind: true,
      };
    }

    // get last note from line
    if (partialSong[i]?.duration && !hasFoundLastNote) {
      hasFoundLastNote = 1; // last note from line found, stop searching.
      lineNoteTotal = partialSong[i]?.location?.layoutBreakNoteIdx; // assign total notes var
    }

    // add count to intermediate notes
    if (hasFoundLastNote === 1) {
      partialSong[i] = {
        ...partialSong[i],
        location: {
          ...partialSong[i].location, lineNoteTotal, layoutBreakLineIdx,
        },
      };
    }

    // for start of song only where first note = symbol
    if (partialSong[i]?.isSymbol && hasFoundLastNote) {
      hasFoundLastNote += 1; // avoid weird loop in note
      partialSong[0] = {
        ...partialSong[0],
        location: {
          ...partialSong[0].location, lineNoteTotal, layoutBreakLineIdx,
        },
      };
      break;
    }

    // get the first note from last layout line and add the total
    // note count from the line to the first note of the layout line
    if (partialSong[i]?.location?.layoutBreakNoteIdx === 1 && hasFoundLastNote === 1 && layoutBreakLineIdx !== 1) {
      hasFoundLastNote += 1; // avoid weird loop in note
      partialSong[i] = {
        ...partialSong[i],
        location: {
          ...partialSong[i].location, lineNoteTotal, layoutBreakLineIdx,
        },
      };
      break;
    }
  }

  return partialSong;
};

export const parseMusicXml = (xmlFileUrl, bpm = 120) => new Promise((resolve, reject) => {

  const fetchUrlAsync = async () => {
    const result = await fetch(xmlFileUrl);
    if (!result.ok) {
      return;
    }

    const xml = await result.text();
    return xml;
  };

  fetchUrlAsync().then((xmlRes) => {
    parseString(xmlRes, { explicitArray: false, explicitChildren: true, preserveChildrenOrder: true }, (error, res) => {

      const parsedSong = res;
      let song = [];
      let m = 0;
      let songTime = 0;
      let measure = 1;
      let repeaterCounter = 0;

      song = makeSymbol(1); // add symbols (clef and 4/4)

      const parts = parsedSong['score-partwise']['part-list']['score-part'];

      // check which part contains the tab
      let mapTabPart = null;
      if (_.isArray(parts)) {
        parts.map((part, i) => {
          if ((part['part-name']?._ && part['part-name']?._.indexOf('Tablature') > -1)
            || part['score-instrument']['instrument-name']?.indexOf('Tablature') > -1
          ) {
            mapTabPart = parsedSong['score-partwise'].part[i];
          }
          return mapTabPart;
        });
      } else {
        mapTabPart = parsedSong['score-partwise'].part;
      }

      /* set the number of notes per line to emulate
      the tablature layout line break */
      let layoutBreakNoteIdx = 2; // notes per layoutBreak lines
      // starts in the 2 because of 4/4 symbol

      let layoutBreakLineIdx = 0;

      // don't show measure lines before song starts
      // https://planmusicapp.atlassian.net/browse/PL-162
      let firstNoteHasBeenPlayed = false;

      // remove lines for backwards repeater
      let hasBackwardRepeater = false;

      mapTabPart.measure.map((ms, idx) => {
        // restart note count at every measure loop
        let measureNoteIdx = 1;

        // next notes will be belong to next layoutBreak line
        if (ms?.print && ms.print['staff-layout']) {

          if (layoutBreakLineIdx > 0) { // avoid performing at the start of the song, no need.
            song = setNotesPerLineCountData(layoutBreakLineIdx, song);
          }

          layoutBreakNoteIdx = 1; // restart note counter for next layout line
          layoutBreakLineIdx += 1;
        }

        // false until we find any repeated part
        let volta = false;
        // chord above the notes (not always)
        const chordsAbove = getChords(ms);

        let hasForwardRepeater = false;

        // set forward repeater if any
        if (ms?.barline?.repeat?.$?.direction === 'forward' || (ms?.barline?.length > 0 && ms?.barline[1]?.repeater?.$?.direction === 'forward')) {
          hasForwardRepeater = true;
        }

        // first line of the measure
        if ((!hasForwardRepeater && !hasBackwardRepeater) || layoutBreakNoteIdx === 1) {
          // by adding condition layoutBreakNoteIdx === 1 we make space for the first notes of the tabs board
          song.push(...makeLine(1, idx, layoutBreakNoteIdx, null, firstNoteHasBeenPlayed));
          layoutBreakNoteIdx += 1;
        }

        // toggle backward if it was true
        hasBackwardRepeater = false;

        if (ms?.barline?.repeat?.$?.direction === 'forward') {
          const { repeater, repeaterCount } = makeRepeater(idx, false, null, repeaterCounter);
          repeaterCounter = repeaterCount;
          layoutBreakNoteIdx += 1; // make space for the asset in calculatedX
          song.push(repeater);
        }

        // set volta if true
        if (ms?.barline) {
          volta = getVoltaNumber(ms.barline);
        }

        // get measure length
        const measureLength = getMeasureLength(ms);

        // declare tuplet outside as buffer
        let isTuplet = false;

        const setActualNote = (note, isFullMeasureRest = false) => {

          // set up dot values for times
          // actual time, and next note time
          const songWithOnlyNotes = getOnlyNotesAndRests(song);
          const lastNote = songWithOnlyNotes[songWithOnlyNotes.length - 1];
          const lastNoteHasDot = !!lastNote?.dot;
          const lastNoteHasDuplet = !!lastNote?.isTuplet;
          const actualNoteHasDot = note?.dot ? setDotValue(note) : false;

          // check if note is rest -silence- note
          if (note?.rest === '' || note?.rest || isFullMeasureRest) {
            // only if rest is staff == 1 add
            // otherwise there's repetition of the note
            // check xml rest note for more context
            if (note.staff === '1' || isFullMeasureRest) {
              m += 1;
              const restLength = note?.type ?? 'complete';
              const { time, restNote } = makeRest(actualNoteHasDot, restLength, m, bpm, note, idx, volta, layoutBreakNoteIdx, layoutBreakLineIdx, song, songTime);
              song.push(restNote);
              songTime = time;
              // keep incrementing when no staff-layoutBreak
              // which means its not the first measure note
              layoutBreakNoteIdx += 1;
            }
          }

          // check if it's tab note
          if (note?.notations?.technical?.fret !== undefined
          // hack: don't add grace notes
          // https://planmusicapp.atlassian.net/browse/PL-369
          && !note?.grace
          ) {
            firstNoteHasBeenPlayed = true;
            const noteLength = note?.type;
            let isChord = false;

            if (note.notations.tuplet?.$?.type === 'start' && note['time-modification']) {
              isTuplet = true;
            }

            m += 1;
            if (m > 1) { // avoid breaking if it's first note
              isChord = note?.$['default-x'] === lastNote.defaultXPos;
            }

            const hasChordAbove = setNoteAbove(note?.$['default-x'], chordsAbove);

            if (note?.$['default-x'] === lastNote?.defaultXPos
                && lastNote.measure === (idx + 1)
                && m > 1) {
              lastNote.isChord = true;
              lastNote.addToTimer = false;
              lastNote.chordIdx = 'not-last';

              // don't increment count since it's
              // an extra note from chord
              layoutBreakNoteIdx -= 1;
            }

            songTime = setTime(m, lastNoteHasDot, bpm, lastNoteHasDuplet, song, songTime);

            const toPush = {
              defaultXPos: note?.$['default-x'],
              isChord,
              chordIdx: isChord ? 'last' : false,
              tie: note?.tie ?? false,
              isTuplet,
              pitch: `${note?.pitch?.step}${note?.pitch?.octave}`,
              dot: actualNoteHasDot,
              bow: getBowDirection(note),
              measure,
              color: 'white',
              show: false,
              location: {
                idx: measureNoteIdx, total: measureLength, layoutBreakNoteIdx, layoutBreakLineIdx,
              },
              name: {
                position: parseInt(note?.notations?.technical?.fret, 10),
                string: parseInt(note?.notations?.technical?.string, 10),
              },
              volta,
              time: songTime,
              duration: noteLength,
              chordAbove: hasChordAbove,
            };

            song.push(toPush);

            if (note.notations.tuplet?.$?.type === 'stop') {
              isTuplet = false;
            }

            // keep incrementing when no staff-layoutBreak
            // which means its not the first measure note
            layoutBreakNoteIdx += 1;

            measureNoteIdx += 1;
          }
        };

        // loop through notes from the measure
        if (ms.note.length > 0) {
          ms.note.map((nt) => setActualNote(nt));
        } else {
          // if there's is not notes array, assume it's a full measure rest
          setActualNote(ms.note, true);
        }

        const lastNoteLocationData = song[song.length - 1]?.location;
        if (
          ms?.barline
          // repeat direction might be in either an array or a single object
            && ((ms.barline.length > 1 && ms.barline[1].repeat?.$?.direction === 'backward')
            || ('repeat' in ms.barline && ms.barline.repeat?.$?.direction === 'backward'))
        ) {
          // toggle flag to avoid pushing line
          // the start of the next measure
          hasBackwardRepeater = true;
          layoutBreakNoteIdx += 1;
          const { repeater, repeaterCount } = makeRepeater(idx, true, lastNoteLocationData, repeaterCounter);
          repeaterCounter = repeaterCount;
          song.push(repeater);
        }

        measure += 1;
        return true;
      });

      song = setNotesPerLineCountData(layoutBreakLineIdx, song);

      layoutBreakNoteIdx += 1;
      song.push(...makeLine(1, 'end', layoutBreakNoteIdx, song[song.length - 1]?.location /* === lastNoteLocationData */, firstNoteHasBeenPlayed));

      const songWithSetChordTime = setChordTime(song);
      const songToArrays = updateObjsToArrays(songWithSetChordTime);
      const finalSong = updateSongData(songToArrays); // set ids, x positions

      resolve(finalSong);
      reject();
    });
  });
});
