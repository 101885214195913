import { useState } from 'react';
import { bool } from 'prop-types';
import { Link } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';
import { Container } from '../container';
import { HeaderLinks } from './headerLinks';

import Logo from '../../../../../assets/img/logo-black.png';
import './header.less';

export const Header = ({ useSecondaryBackground }) => {
  const [showLinks, setShowLinks] = useState(false);

  return (
    <>
      <div className='header__ellipse header__ellipse--left' />
      <div className='header__ellipse header__ellipse--right' />
      <Container>
        <header className='header'>
          <Link to='/' className='header__logo'>
            <img src={Logo} alt='logo' />
          </Link>
          <div className='header__icon mobile-only'>
            <Icon
              className={showLinks ? 'header__icon__close' : ''}
              name={showLinks ? 'close' : 'bars'}
              size='big'
              onClick={() => setShowLinks(!showLinks)}
            />
          </div>
          <HeaderLinks className='header__links__desktop desktop-only' hideLinks={null} useSecondaryBackground={useSecondaryBackground} />
          <HeaderLinks
            className='mobile-only'
            hideLinks={() => setShowLinks(!showLinks)}
            style={{ display: showLinks ? 'flex' : 'none' }}
            useSecondaryBackground={useSecondaryBackground}
          />
        </header>
      </Container>
    </>
  );
};

Header.propTypes = {
  useSecondaryBackground: bool.isRequired,
};
